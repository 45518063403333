import React from "react";
import { Box, Link, useMediaQuery, useTheme } from "@mui/material";
import IconGoogle from "../../Icons/google";
import { getLanguage } from "../../lib/language";

function GooglePlayButtonAPK() {
  const lng = getLanguage() === "ru" || getLanguage() === "en";
	const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Link
        id="storeBtn"
        href="https://play.google.com/store/apps/details?id=md.letz.driver"
        target={"_blank"}
        sx={{
          height: 45,
          mt: lng && lgUp ? 0 : "15px",
          width: 135,
          backgroundColor: "black",
          userSelect: "none",
          textDecoration: "none",
          color: "#000",
          pr: "12px",
          pl: "10px",
          border: "solid 2px #000",
          borderRadius: "8px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        translate="no"
      >
        <Box sx={{ pt: "3px" }}>
          <IconGoogle />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            color: "white",
          }}
        >
          <Box
            sx={{
              fontWeight: "500",
              fontFamily: "Montserrat",
              fontSize: "10px",
              letterSpacing: "0px",
              textAlign: "start",
            }}
          >
            GET IT ON
          </Box>
          <Box
            sx={{
              fontSize: "18px",
              fontFamily: "Montserrat",
              fontWeight: "500",
              letterSpacing: "-1.2px",
              mt: "-3px",
            }}
          >
            Google Play
          </Box>
        </Box>
      </Link>
    </>
  );
}

export default GooglePlayButtonAPK;

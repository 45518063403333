export const data = [
  "for_taxi_drivers",
  "letz_is_an_indispansable",
  "key_features",
  "work_smarter",
  "maximize_your_revenue",
  "simple_interface",
  "flexible_schedule",
  "local_and_global",
];

export const getImg = (number) => {
  switch (number) {
    case 3:
      return <>🚖</>;
    case 4:
      return <>📈</>;
    case 5:
      return <>📱</>;
    case 6:
      return <>📅</>;
    case 7:
      return <>🌐</>;
    default:
      return null;
  }
};

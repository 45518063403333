const style = {
  container: { width: "100%" },
  parent: {
    height: { xs: "calc(100vh - 100px)", sm: "none" },
    minHeight: { xs: "500px", sm: "none" },
    display: "flex",
    justifyContent: "center",
  },
  child: {
    mt: "35vh",
    width: "630px",
    display: "flex",
    flexDirection: "column",
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
  },
  description: {
    mt: 5,
    fontSize: { xs: "8.28vw", sm: "49.2px" },
    fontFamily: "Montserrat",
    fontWeight: 600,
    textAlign: "center",
  },
};

export default style;

import React, { useEffect }  from 'react';
import { useLocation } from "react-router-dom";
import { Box, Typography, Container} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import undrawDownload from '../../images/undraw_download.png'
import GooglePlayButton from '../../components/buttons/GooglePlayButton';
import AppStoreButton from '../../components/buttons/AppStoreButton';
import QR from '../../Icons/qr_code';
import '../../styles/App.css';

function Download() {

  const { t } = useTranslation(['download', 'common']);
  const theme = useTheme();
  const { pathname } = useLocation();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));
	const mdUp = useMediaQuery(theme.breakpoints.up('md'));
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'));  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); 
  
  useEffect(() => {

    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=md.letz.client'
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = 'https://apps.apple.com/ru/app/letz/id1624080667'
    } 

});

  return (
    <>
      <Container sx={{minHeight: 'calc(100vh - 285px)', width: '100%'}}>

        {!smUp && (
          <>
            <Box sx={{ mt: '185px', mb: '80px', textAlign: 'center'}}>           
              <Typography sx={{
                  color: '#000',
                  fontFamily: 'Montserrat',
                  fontSize: '42px',
                  letterSpacing: '-2.5px',
                  fontWeight: 700,
                }}>  {t('scan_the_QR')}                
              </Typography>
            
            <Box sx={{display: 'flex', flexDirection: 'column', mt: '50px'}}>             
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',														
                  pt: '30px',
                  width: '100%'}}>
                    <img src={undrawDownload} alt='undrawDownload' height={236} width={270} />
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%'}}>
                  <Box sx={{display: 'flex', flexDirection: 'column', ml: 0, mt: 9}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>								
                     <QR/> 
                    </Box>
                  </Box> 
                  <ul style={{fontFamily: 'Montserrat', fontWeight: 600, fontSize: '15.5px', lineHeight: '35px', textAlign: 'left', color: '#3e4958', marginTop: '50px'}}>
                  <li>{t('scan_the_code')}</li>
                  <li>{t('you_will_be_directed')}</li>
                  <li>{t('download_the_app')}</li>									
                  </ul>
                </Box>             
                <Box className='respons__btn' sx={{mt: '50px', display: 'flex', justifyContent: 'space-between' && 'space-around'}}>
                <GooglePlayButton/>
                <AppStoreButton/>								
                </Box>
              </Box>  
						</Box> 
          </>
        )}

        {!mdUp && smUp && (
          <>
            <Box sx={{ mt: '185px', mb: '80px', textAlign: 'center'}}>           
              <Typography sx={{
                  color: '#000',
                  fontFamily: 'Montserrat',
                  fontSize: '42px',
                  letterSpacing: '-2.5px',
                  fontWeight: 700,
                }}>  {t('scan_the_QR')}                
              </Typography>
            
            <Box sx={{display: 'flex', flexDirection: 'column', mt: '50px'}}>             
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',														
                  pt: '30px',
                  width: '100%'}}>
                    <img src={undrawDownload} alt='undrawDownload' height={262} width={300} />
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%'}}>
                  <Box sx={{display: 'flex', flexDirection: 'column', ml: 0, mt: 9}}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>								
                     <QR/> 
                    </Box>
                  </Box>  
                  <ul style={{fontFamily: 'Montserrat', fontWeight: 600, fontSize: '15.5px', lineHeight: '35px', textAlign: 'left', color: '#3e4958', marginTop: '50px'}}>
                  <li>{t('scan_the_code')}</li>
                  <li>{t('you_will_be_directed')}</li>
                  <li>{t('download_the_app')}</li>									
                  </ul>
                </Box>             
                <Box sx={{mt: '50px', display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{mr: 10}}><GooglePlayButton/></Box>
                  <AppStoreButton/>							
                </Box>
              </Box>  
						</Box> 
          </>
        )}

        {!lgUp && mdUp && (
          <>
            <Box sx={{ mt: '185px', textAlign: 'center'}}>           
              <Typography sx={{
                  color: '#000',
                  fontFamily: 'Montserrat',
                  fontSize: '42px',
                  letterSpacing: '-2.5px',
                  fontWeight: 700,
                }}>  {t('scan_the_QR')}                
              </Typography>
              <Box sx={{display: 'flex', justifyContent: 'center',pt: '35px', mt: '70px'}}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'end',														
                    pt: '20px',
                    width: '40%'}}>
                      <img src={undrawDownload} alt='undrawDownload' height={262} width={300} />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: '60%'}}>
                      <Box sx={{ml: -15, display: 'flex', alignSelf: 'center'}}>								
                       <QR/> 
                      </Box>
                      <ul style={{marginTop: '30px', fontFamily: 'Montserrat', fontWeight: 600, fontSize: '15.5px', lineHeight: '35px', textAlign: 'left', color: '#3e4958'}}>
                      <li>{t('scan_the_code')}</li>
                      <li>{t('you_will_be_directed')}</li>
                      <li>{t('download_the_app')}</li>									
                      </ul>
                  </Box>
                </Box>   
                <Box sx={{mt: '80px', display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{mr: '100px'}}><GooglePlayButton/></Box>
                  <AppStoreButton/>							
                </Box>
						</Box> 
          </>
        )}

        {lgUp &&  (
          <>
            <Box sx={{ mt: '185px', textAlign: 'center'}}>
              <Typography sx={{
                  color: '#000',
                  fontFamily: 'Montserrat',
                  fontSize: '42px',
                  letterSpacing: '-2.5px',
                  fontWeight: 700,
                }}>  {t('scan_the_QR')}                
              </Typography>            
                <Box sx={{display: 'flex', justifyContent: 'center',pt: '35px', mt: '70px'}}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'end',														
                    pt: '20px',
                    width: '40%'}}>
                      <img src={undrawDownload} alt='undrawDownload' height={262} width={300} />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: '60%'}}>
                      <Box sx={{ml: -15, display: 'flex', alignSelf: 'center'}}>		
                          <QR/> 
                      </Box>
                      <ul style={{marginTop: '30px', fontFamily: 'Montserrat', fontWeight: 600, fontSize: '15.5px', lineHeight: '35px', textAlign: 'left', color: '#3e4958'}}>
                      <li>{t('scan_the_code')}</li>
                      <li>{t('you_will_be_directed')}</li>
                      <li>{t('download_the_app')}</li>									
                      </ul>
                  </Box>
                </Box> 
                <Box sx={{mt: '80px', display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{mr: '120px'}}><GooglePlayButton/></Box>
                  <AppStoreButton/>							
                </Box>             
              </Box>  
          </>
        )} 

      </Container>
    </>
  )
}

export default Download
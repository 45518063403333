import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { ContentPack } from "./Utils/Content";
import { StructurePack } from "./Utils/Structure";

const Instruction = () => {
  const { t } = useTranslation("driver");
  const [expandedMain, setExpandedMain] = useState(false);
  const [expandedSecondary, setExpandedSecondary] = useState(false);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const handleChangeMain = (panel) => (event, isExpanded) => {
    setExpandedMain(isExpanded ? panel : false);
    if (isExpanded !== "panel1") {
      setExpandedSecondary(false);
    }
  };
  const handleChangeSecondary = (panel) => (event, isExpanded) => {
    setExpandedSecondary(isExpanded ? panel : false);
  };

  return (
    <Container
      sx={{
        minHeight: smUp ? "calc(100vh - 285px)" : "calc(100vh - 235px)",
        width: "100%",
        mt: smUp ? "185px" : "135px",
      }}
    >
      {StructurePack?.panelMain?.map((name, i) => {
        return (
          <Accordion
            key={i}
            sx={{
              backgroundColor:
                expandedMain === name &&
                StructurePack?.panelSecondary[i].length !== 1
                  ? "rgba(0,0,0,0.04)"
                  : "none",
            }}
            expanded={expandedMain === name}
            onChange={handleChangeMain(name)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {ContentPack?.title(
                expandedMain,
                StructurePack?.paragraphMainTitles[i],
                smUp,
                t
              )}
            </AccordionSummary>
            <AccordionDetails>
              {StructurePack?.panelSecondary[i]?.map((name, j) => {
                if (StructurePack?.panelSecondary[i].length === 1) {
                  return (
                    <Box key={j}>
                      {ContentPack?.content[i][0](smUp, t, expandedMain)}
                    </Box>
                  );
                } else {
                  return (
                    <Accordion
                      key={j}
                      expanded={expandedSecondary === name}
                      onChange={handleChangeSecondary(name)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        {ContentPack?.title(
                          expandedMain,
                          StructurePack?.paragraphSecondaryTitles[i][j],
                          smUp,
                          t
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        {ContentPack?.content[i][j](smUp, t, expandedMain)}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Typography
        sx={{
          px: smUp ? "2%" : "5%",
          mt: smUp ? "35px" : "25px",
          width: smUp ? "96%" : "90%",
          flexShrink: 0,
          fontFamily: "Montserrat",
          fontSize: smUp ? 20 : 16,
          fontWeight: 700,
        }}
      >
        {t("text_2.1.2")}
      </Typography>
    </Container>
  );
};

export default Instruction;

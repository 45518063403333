import { useMediaQuery, useTheme } from "@mui/material";

export const ScreenResolutions = () => {
  const theme = useTheme();
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  // const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const screenResolutions = [
    xsUp, 
    smUp, 
    mdUp, 
    lgUp, 
    // xlUp
  ];

  return screenResolutions;
};

import React from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';	

function LetsStart() {

	const { t } = useTranslation(['header', 'common']);
	
  return (<>

		<NavLink 	to='/driver'
							className='buttonDriver' 
							style={{height: '40px', 
							paddingLeft: "24px",
							paddingRight: "24px",
							userSelect: 'none', 
							borderRadius: '8px', 
							display: 'flex', 
							alignItems: 'center', 
							justifyContent: 'center', 
							backgroundColor: 'black', 
							color: 'white', 
							fontSize: '17px',	
							fontFamily: 'Montserrat', 
							fontWeight: 600, 
							textDecoration: 'none', 
							textTransform: 'uppercase'}}>	{t('download')} 
		</NavLink>

  </>)
}

export default LetsStart
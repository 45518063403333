import React from 'react'
import { Box, Link } from '@mui/material';
import IconApple from '../../Icons/apple';

function AppStoreButton() {
  return (<>

	<Link	id='storeBtn' 
				href='https://apps.apple.com/ru/app/letz/id1624080667'
				target={'_blank'}
				sx={{height: 45, 
				width: 135, 
				backgroundColor: "black",
				userSelect: 'none', 
				textDecoration: 'none', 
				color: '#000', 
				pr: '12px', 
				pl: '10px', 
				border: 'solid 2px #000', 
				borderRadius: '8px', 
				cursor: 'pointer', 
				display: 'flex', 
				justifyContent: 'space-between', 
				alignItems: 'center'}}
				translate="no">
		<Box>
			<IconApple/>
		</Box>
		<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', color: "white"}}>
			<Box sx={{fontWeight: '500', fontFamily: 'Montserrat',  fontSize: '11px', letterSpacing: '0px', textAlign: "start"}}>Download on the</Box>
			<Box sx={{fontSize: '19px', fontFamily: 'Montserrat',  fontWeight: '500', letterSpacing: '-0.3px', mt: '-5px'}}>App Store</Box>
		</Box>											
	</Link>	

  </>)
}

export default AppStoreButton
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Container, Grid, TextField, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import IconBadge from '../../../Icons/badge';
import IconCar from '../../../Icons/car';
import IconCard from '../../../Icons/credit';
import '../../../styles/App.css';
import EmailIcon from '@mui/icons-material/Email';
import { enqueueSnackbar } from "notistack";
// import useInput from '../Hooks/useInput';
const phone = /^\+?[1-9][0-9]{0,30}$/

function DriverFrom() {
  const { t } = useTranslation(['driver', 'common']);
  const theme = useTheme();
  const { pathname } = useLocation();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isDirty, setDirty] = useState(false)
  const [notANumber, setNotNumber] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    phone.test(Number(phoneNumber)) ? setNotNumber(false) : setNotNumber(true)
  }, [phoneNumber])

  // const name = useInput('', { isEmpty: true, minLength: 2, maxLength: 30, notAName: true })
  // const lastName = useInput('', { isEmpty: true, minLength: 2, maxLength: 30, notAName: true })
  // const email = useInput('', { isEmpty: true, isEmail: true })
  // const phoneNumberCheck = useInput('', { isEmpty: true, minLength: 6, maxLength: 20, notANumber: true })
  // const experience = useInput('', { isEmpty: true, minLength: 1, maxLength: 2, notANumber: true })
  // const message = useInput('')

  const SendMessage = async () => {
    setDirty(true);

    if (phoneNumber.length < 3 || phoneNumber.length > 20) {
      // alert(t('check_your_phone_number'))
      return;
    } else if (notANumber) {
      // alert(t('check_your_phone_number'))
      return;
    }
    setLoading(true)
    await fetch('veritas/v1/Ping/NewM', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        ApiKey: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({
        name: name,
        phone: phoneNumber,
        message: message,
      })
    })
      .then((res) => checkResponse(res))
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(t("message_dont_sent"), { variant: "error" });
      })
  };

  const checkResponse = (res) => {
    if (!res || res.status >= 300) {
      setLoading(false);
      enqueueSnackbar(t("message_dont_sent"), { variant: "error" })
      // alert(t('message_dont_sent'))
    } else {
      setLoading(false);
      enqueueSnackbar(t("message_sent"), { variant: "success" })
      // alert(t('message_sent'));
      setName('');
      setPhoneNumber('');
      setMessage('');
      setDirty(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Container sx={{ height: '100%', width: '100%', pt: 8, pb: 8 }}>
        {!smUp && (
          <>
            {/* <Box sx={{ marginTop: '120px', textAlign: 'center' }}>
              <Typography sx={{
                color: '#1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '45px',
                lineHeight: '64px',
                fontWeight: '700',
                letterSpacing: -1.5
              }}>
                {t('do_you_want_to_be_a_Letz_driver')}
              </Typography>
            </Box> */}
            {/* <Box sx={{ marginTop: '30px' }}>
              <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item>
                  <Box sx={{
                    minWidth: 200,
                    minHeight: '190px',
                    pb: 1,
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)',
                      boxShadow: '0px 0px 51px 0px #E4E4E4',
                      transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconBadge />
                      </Box>
                      <Typography component='p' sx={{ width: '100%', textAlign: 'center', whiteSpace: 'pre-line', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('with_all_social_packages')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{
                    minWidth: 200,
                    minHeight: '190px',
                    pb: 1,
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCar className='iconHover' />
                      </Box>
                      <Typography component='p' sx={{ width: '100%', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('the_opportunity_to_buy_a_car')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{
                    minWidth: 200,
                    minHeight: '190px',
                    pb: 1,
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)',
                      boxShadow: '0px 0px 51px 0px #E4E4E4',
                      transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCard className='iconHover' />
                      </Box>
                      <Typography component='p' sx={{ width: '100%', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('bonus_system')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
            <Box sx={{ mb: "20px", mt: "40px", padding: '20px' }}>
              <Typography sx={{
                textAlign: 'center',
                color: '1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                lineHeight: '25px',
                fontWeight: '700',
                letterSpacing: '0px'
              }}>
                {t('fill_out_this_form')}
              </Typography>

              {/* <Box sx={{ marginTop: '50px', position: 'relative' }}>
                <Grid sx={{ display: 'flex', width: '100%' }}>
                  <Grid item sx={{ width: '100%', }}>
                    <TextField
                      value={name.value}
                      onChange={e => name.onChange(e)}
                      onBlur={e => name.onBlur(e)}
                      id='standard-basic'
                      label={t('name')}
                      placeholder={t('example_name')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((name.isDirty && name.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((name.isDirty && name.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_name')}</Typography>) ||
                      ((name.isDirty && name.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                      ((name.isDirty && name.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                  </Grid>
                </Grid>
                <Grid item sx={{ marginTop: '50px' }}>
                  <TextField
                    value={lastName.value}
                    onChange={e => lastName.onChange(e)}
                    onBlur={e => lastName.onBlur(e)}
                    id='standard-basic'
                    label={t('last_name')}
                    placeholder={t('example_last_name')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((lastName.isDirty && lastName.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((lastName.isDirty && lastName.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_Last_name')}</Typography>) ||
                    ((lastName.isDirty && lastName.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                    ((lastName.isDirty && lastName.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                </Grid>
                <Grid sx={{ marginTop: '50px' }}>
                  <Grid item>
                    <TextField
                      value={phoneNumber.value}
                      onChange={e => phoneNumber.onChange(e)}
                      onBlur={e => phoneNumber.onBlur(e)}
                      id='standard-basic'
                      label={t('phone_number')}
                      placeholder={t('example_number')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((phoneNumber.isDirty && phoneNumber.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((phoneNumber.isDirty && phoneNumber.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                      ((phoneNumber.isDirty && phoneNumber.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('incomplete_number')}</Typography>) ||
                      ((phoneNumber.isDirty && phoneNumber.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols_twelve')}</Typography>)}
                  </Grid>
                  <Grid item sx={{ marginTop: '50px' }}>
                    <TextField
                      value={experience.value}
                      onChange={e => experience.onChange(e)}
                      onBlur={e => experience.onBlur(e)}
                      id='standard-basic'
                      label={t('driving_experience')}
                      placeholder={t('example_year_driving')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((experience.isDirty && experience.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((experience.isDirty && experience.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                      ((experience.isDirty && experience.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('driver_experience')}</Typography>)}
                  </Grid>
                  <Grid item sx={{ marginTop: '50px' }}>
                    <TextField
                      value={email.value}
                      onChange={e => email.onChange(e)}
                      onBlur={e => email.onBlur(e)}
                      id='standard-basic'
                      label={t('email')}
                      placeholder={t('example_email')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((email.isDirty && email.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((email.isDirty && email.emailError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('wrong_email')}</Typography>)}
                  </Grid>
                  <Grid item sx={{ marginTop: '50px', width: '100%' }}>
                    <TextareaAutosize
                      value={message.value}
                      onChange={e => message.onChange(e)}
                      onBlur={e => message.onBlur(e)}
                      className='driver-textarea'
                      style={{
                        width: '100%',
                        padding: '11px 6px',
                        outline: 'none',
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        cursor: 'pointer'
                      }}
                      minRows='12'
                      placeholder={t('message')} />
                  </Grid>
                  <Grid item sx={{ marginTop: '30px', width: '100%' }}>
                    <Button
                      sx={{
                        px: 3,
                        height: '50px',
                        background: '#000',
                        color: '#fff',
                        fontSize: '18px',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        borderRadius: '8px',
                        textTransform: 'none',
                        '&:hover': { background: '#2f2e41' }
                      }}>{t('become_a_driver')}
                    </Button>
                  </Grid>
                </Grid>
              </Box> */}
            </Box>
          </>
        )}
        {!mdUp && smUp && (
          <>
            <Box sx={{ marginTop: '120px', textAlign: 'center' }}>
              <Typography sx={{
                color: '#1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '50px',
                lineHeight: '64px',
                fontWeight: '700',
                letterSpacing: -1.5
              }}>
                {t('do_you_want_to_be_a_Letz_driver')}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '40px' }}>
              <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item md={4} lg={4} sx={{ margin: '15px' }}>
                  <Box sx={{
                    minWidth: 300,
                    minHeight: '175px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)',
                      boxShadow: '0px 0px 51px 0px #E4E4E4',
                      transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{
                        display: 'flex',
                        width: '50px',
                        mb: 3,
                        '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' }
                      }}>
                        <IconBadge />
                      </Box>
                      <Typography component='div' sx={{ width: '100%', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('with_all_social_packages')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4} lg={4} sx={{ margin: '15px' }}>
                  <Box sx={{
                    minWidth: 300,
                    minHeight: '175px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCar className='iconHover' />
                      </Box>
                      <Typography component='div' sx={{ width: '100%', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('the_opportunity_to_buy_a_car')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4} lg={4} sx={{ margin: '15px' }}>
                  <Box sx={{
                    minWidth: 300,
                    minHeight: '175px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCard className='iconHover' />
                      </Box>
                      <Typography component='div' sx={{ width: '100%', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('bonus_system')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ margin: '20px 0', padding: '20px' }}>
              <Typography sx={{
                textAlign: 'center',
                color: '1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                lineHeight: '25px',
                fontWeight: '700',
                letterSpacing: '0px'
              }}>
                {t('fill_out_this_form')}
              </Typography>
            </Box>

            {/* <Box sx={{ marginTop: '50px' }}>
              <Grid sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Grid item sx={{ width: '45%', position: 'relative' }}>
                  <TextField
                    value={name.value}
                    onChange={e => name.onChange(e)}
                    onBlur={e => name.onBlur(e)}
                    id='standard-basic'
                    label={t('name')}
                    placeholder={t('example_name')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((name.isDirty && name.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((name.isDirty && name.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_name')}</Typography>) ||
                    ((name.isDirty && name.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                    ((name.isDirty && name.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                </Grid>
                <Grid item sx={{ width: '45%', position: 'relative' }}>
                  <TextField
                    value={lastName.value}
                    onChange={e => lastName.onChange(e)}
                    onBlur={e => lastName.onBlur(e)}
                    id='standard-basic'
                    label={t('last_name')}
                    placeholder={t('example_last_name')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((lastName.isDirty && lastName.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((lastName.isDirty && lastName.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_Last_name')}</Typography>) ||
                    ((lastName.isDirty && lastName.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                    ((lastName.isDirty && lastName.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: '35px' }}>
                <Grid item>

                  <TextField
                    value={phoneNumber.value}
                    onChange={e => phoneNumber.onChange(e)}
                    onBlur={e => phoneNumber.onBlur(e)}
                    id='standard-basic'
                    label={t('phone_number')}
                    placeholder={t('example_number')}
                    variant='standard'
                    sx={{
                      width: '100%',
                      '&:hover': { outlineColor: '#7879f0' }
                    }}
                  />
                  {((phoneNumber.isDirty && phoneNumber.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((phoneNumber.isDirty && phoneNumber.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                    ((phoneNumber.isDirty && phoneNumber.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('incomplete_number')}</Typography>) ||
                    ((phoneNumber.isDirty && phoneNumber.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols_twelve')}</Typography>)}

                </Grid>
                <Grid item sx={{ marginTop: '35px' }}>
                  <TextField
                    value={experience.value}
                    onChange={e => experience.onChange(e)}
                    onBlur={e => experience.onBlur(e)}
                    id='standard-basic'
                    label={t('driving_experience')}
                    placeholder={t('example_year_driving')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((experience.isDirty && experience.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((experience.isDirty && experience.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                    ((experience.isDirty && experience.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('driver_experience')}</Typography>)}
                </Grid>
                <Grid item sx={{ marginTop: '30px' }}>

                  <TextField
                    value={email.value}
                    onChange={e => email.onChange(e)}
                    onBlur={e => email.onBlur(e)}
                    id='standard-basic'
                    label={t('email')}
                    placeholder={t('example_email')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((email.isDirty && email.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((email.isDirty && email.emailError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('wrong_email')}</Typography>)}
                </Grid>
                <Grid item sx={{ marginTop: '35px', width: '100%' }}>
                  <TextareaAutosize
                    value={message.value}
                    onChange={e => message.onChange(e)}
                    onBlur={e => message.onBlur(e)}
                    className='driver-textarea'
                    style={{
                      width: '100%',
                      padding: '11px 6px',
                      outline: 'none',
                      borderRadius: '8px',
                      fontSize: '16px',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#7879f0' }
                    }}
                    minRows='12'
                    placeholder={t('message')} />
                </Grid>
                <Grid item sx={{ marginTop: '30px', width: '100%' }}>
                  <Button
                    sx={{
                      px: 3,
                      height: '50px',
                      background: '#000',
                      color: '#fff',
                      fontSize: '18px',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      borderRadius: '8px',
                      textTransform: 'none'
                    }}>{t('become_a_driver')}
                  </Button>
                </Grid>
              </Grid>
            </Box> */}
          </>
        )}
        {!lgUp && mdUp && (
          <>
            <Box sx={{ marginTop: '120px', textAlign: 'center' }}>
              <Typography sx={{
                color: '#1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '60px',
                lineHeight: '64px',
                fontWeight: '700',
                letterSpacing: -1.5
              }}>
                {t('do_you_want_to_be_a_Letz_driver')}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '40px' }}>
              <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item sx={{ margin: '15px', width: '240px' }}>
                  <Box sx={{
                    minWidth: 240,
                    minHeight: '370px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 5, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconBadge />
                      </Box>
                      <Typography component='p' sx={{ width: '215px', textAlign: 'center', whiteSpace: 'pre-line', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('with_all_social_packages')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sx={{ margin: '15px', width: '240px' }}>
                  <Box sx={{
                    minWidth: 240,
                    minHeight: '370px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 5, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCar className='iconHover' />
                      </Box>
                      <Typography component='p' sx={{ width: '215px', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('the_opportunity_to_buy_a_car')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sx={{ margin: '15px', width: '240px' }}>
                  <Box sx={{
                    minWidth: 240,
                    minHeight: '370px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 5, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCard className='iconHover' />
                      </Box>
                      <Typography component='p' sx={{ width: '215px', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('bonus_system')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ margin: '20px 0', padding: '20px' }}>
              <Typography sx={{
                textAlign: 'center',
                color: '1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                lineHeight: '25px',
                fontWeight: '700',
                letterSpacing: '0px'
              }}>
                {t('fill_out_this_form')}
              </Typography>

              {/* <Box sx={{ mt: '50px' }}>
                <Grid sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <Grid item sx={{ width: '45%', position: 'relative' }}>
                    <TextField
                      value={name.value}
                      onChange={e => name.onChange(e)}
                      onBlur={e => name.onBlur(e)}
                      id='standard-basic'
                      label={t('name')}
                      placeholder={t('example_name')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((name.isDirty && name.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((name.isDirty && name.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_name')}</Typography>) ||
                      ((name.isDirty && name.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                      ((name.isDirty && name.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                  </Grid>
                  <Grid item sx={{ width: '45%' }}>
                    <TextField
                      value={lastName.value}
                      onChange={e => lastName.onChange(e)}
                      onBlur={e => lastName.onBlur(e)}
                      id='standard-basic'
                      label={t('last_name')}
                      placeholder={t('example_last_name')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((lastName.isDirty && lastName.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((lastName.isDirty && lastName.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_Last_name')}</Typography>) ||
                      ((lastName.isDirty && lastName.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                      ((lastName.isDirty && lastName.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                  </Grid>
                </Grid>
                <Grid sx={{ marginTop: '35px' }}>
                  <Grid item>

                    <TextField
                      value={phoneNumber.value}
                      onChange={e => phoneNumber.onChange(e)}
                      onBlur={e => phoneNumber.onBlur(e)}
                      id='standard-basic'
                      label={t('phone_number')}
                      placeholder={t('example_number')}
                      variant='standard'
                      sx={{
                        width: '100%',
                        '&:hover': { outlineColor: '#7879f0' }
                      }}
                    />
                    {((phoneNumber.isDirty && phoneNumber.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((phoneNumber.isDirty && phoneNumber.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                      ((phoneNumber.isDirty && phoneNumber.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('incomplete_number')}</Typography>) ||
                      ((phoneNumber.isDirty && phoneNumber.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols_twelve')}</Typography>)}

                  </Grid>
                  <Grid item sx={{ marginTop: '35px' }}>
                    <TextField
                      value={experience.value}
                      onChange={e => experience.onChange(e)}
                      onBlur={e => experience.onBlur(e)}
                      id='standard-basic'
                      label={t('driving_experience')}
                      placeholder={t('example_year_driving')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((experience.isDirty && experience.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((experience.isDirty && experience.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                      ((experience.isDirty && experience.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('driver_experience')}</Typography>)}
                  </Grid>
                  <Grid item sx={{ marginTop: '30px' }}>

                    <TextField
                      value={email.value}
                      onChange={e => email.onChange(e)}
                      onBlur={e => email.onBlur(e)}
                      id='standard-basic'
                      label={t('email')}
                      placeholder={t('example_email')}
                      variant='standard'
                      sx={{ width: '100%' }}
                    />
                    {((email.isDirty && email.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                      ((email.isDirty && email.emailError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('wrong_email')}</Typography>)}
                  </Grid>
                  <Grid item sx={{ marginTop: '35px', width: '100%' }}>
                    <TextareaAutosize
                      value={message.value}
                      onChange={e => message.onChange(e)}
                      onBlur={e => message.onBlur(e)}
                      className='driver-textarea'
                      style={{
                        width: '100%',
                        padding: '11px 6px',
                        outline: 'none',
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        cursor: 'pointer'
                      }}
                      minRows='12'
                      placeholder={t('message')} />
                  </Grid>
                  <Grid item sx={{ marginTop: '30px', width: '100%' }}>
                    <Button
                      sx={{
                        px: 3,
                        height: '50px',
                        background: '#000',
                        color: '#fff',
                        fontSize: '18px',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        borderRadius: '8px',
                        textTransform: 'none',
                        '&:hover': { background: '#2f2e41' }
                      }}>{t('become_a_driver')}
                    </Button>
                  </Grid>
                </Grid>
              </Box> */}
            </Box>
          </>
        )}
        {lgUp && (
          <>
            <Box sx={{ marginTop: '120px', textAlign: 'center' }}>
              <Typography sx={{
                color: '#1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '60px',
                lineHeight: '64px',
                fontWeight: 700,
                letterSpacing: -1.5
              }}>
                {t('do_you_want_to_be_a_Letz_driver')}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '50px' }}>
              <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item md={4} lg={4} sx={{ margin: '30px' }}>
                  <Box sx={{
                    minWidth: 300,
                    minHeight: '370px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ py: '50px', px: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 5, '&:hover': { cursor: 'pointer', color: '#7879f0;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconBadge />
                      </Box>
                      <Typography component='div' sx={{ width: '220px', textAlign: 'center', fontSize: '20px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('with_all_social_packages')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4} lg={4} sx={{ margin: '30px' }}>
                  <Box sx={{
                    minWidth: 300,
                    minHeight: '370px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '0px solid rgba(0, 0, 0, 1)',
                      boxShadow: '0px 0px 51px 0px #E4E4E4',
                      transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ py: '50px', px: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 5, '&:hover': { cursor: 'pointer', color: '#7879f0;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCar className='iconHover' />
                      </Box>
                      <Typography component='div' sx={{ width: '220px', textAlign: 'center', fontSize: '20px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('the_opportunity_to_buy_a_car')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4} lg={4} sx={{ margin: '30px' }}>
                  <Box sx={{
                    minWidth: 300,
                    minHeight: '370px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ py: '50px', px: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 5, '&:hover': { cursor: 'pointer', color: '#7879f0;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCard className='iconHover' />
                      </Box>
                      <Typography component='div' sx={{ width: '220px', textAlign: 'center', fontSize: '20px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('bonus_system')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ margin: '20px 0', padding: '20px' }}>
              <Typography sx={{
                textAlign: 'center',
                color: '1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                lineHeight: '25px',
                fontWeight: 700,
                letterSpacing: '0px',
              }}>
                {t('fill_out_this_form')}
              </Typography>
            </Box>
            {/* <Box sx={{ mt: '50px' }}>
              <Grid sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Grid item sx={{ width: '45%', position: 'relative' }}>
                  <TextField
                    value={name.value}
                    onChange={e => name.onChange(e)}
                    onBlur={e => name.onBlur(e)}
                    id='standard-basic'
                    label={t('name')}
                    placeholder={t('example_name')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((name.isDirty && name.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((name.isDirty && name.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_name')}</Typography>) ||
                    ((name.isDirty && name.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                    ((name.isDirty && name.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                </Grid>
                <Grid item sx={{ width: '45%' }}>
                  <TextField
                    value={lastName.value}
                    onChange={e => lastName.onChange(e)}
                    onBlur={e => lastName.onBlur(e)}
                    id='standard-basic'
                    label={t('last_name')}
                    placeholder={t('example_last_name')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((lastName.isDirty && lastName.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((lastName.isDirty && lastName.notString) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_Last_name')}</Typography>) ||
                    ((lastName.isDirty && lastName.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_two')}</Typography>) ||
                    ((lastName.isDirty && lastName.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols')}</Typography>)}
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: '35px' }}>
                <Grid item>

                  <TextField
                    value={phoneNumber.value}
                    onChange={e => phoneNumber.onChange(e)}
                    onBlur={e => phoneNumber.onBlur(e)}
                    id='standard-basic'
                    label={t('phone_number')}
                    placeholder={t('example_number')}
                    variant='standard'
                    sx={{
                      width: '100%',
                      '&:hover': { outlineColor: '#7879f0' }
                    }}
                  />
                  {((phoneNumber.isDirty && phoneNumber.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((phoneNumber.isDirty && phoneNumber.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                    ((phoneNumber.isDirty && phoneNumber.minLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('incomplete_number')}</Typography>) ||
                    ((phoneNumber.isDirty && phoneNumber.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols_twelve')}</Typography>)}

                </Grid>
                <Grid item sx={{ marginTop: '35px' }}>
                  <TextField
                    value={experience.value}
                    onChange={e => experience.onChange(e)}
                    onBlur={e => experience.onBlur(e)}
                    id='standard-basic'
                    label={t('driving_experience')}
                    placeholder={t('example_year_driving')}
                    variant='standard'
                    sx={{ width: '100%' }}
                  />
                  {((experience.isDirty && experience.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((experience.isDirty && experience.notNumber) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
                    ((experience.isDirty && experience.maxLengthError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('driver_experience')}</Typography>)}
                </Grid>
                <Grid item sx={{ marginTop: '30px' }}>

                  <TextField
                    value={email.value}
                    onChange={e => email.onChange(e)}
                    onBlur={e => email.onBlur(e)}
                    id='standard-basic'
                    label={t('email')}
                    placeholder={t('example_email')}
                    variant='standard'
                    sx={{ width: '100%', color: 'red' }}
                  />
                  {((email.isDirty && email.isEmpty) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('empty')}</Typography>) ||
                    ((email.isDirty && email.emailError) && <Typography sx={{ position: 'absolute', fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('wrong_email')}</Typography>)}
                </Grid>
                <Grid item sx={{ marginTop: '35px', width: '100%', position: 'relative' }}>
                  <TextareaAutosize
                    value={message.value}
                    onChange={e => message.onChange(e)}
                    onBlur={e => message.onBlur(e)}
                    className='driver-textarea'
                    style={{
                      width: '100%',
                      padding: '11px 6px',
                      outline: 'none',
                      borderRadius: '8px',
                      fontSize: '16px',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      cursor: 'pointer',
                      color: 'black'
                    }}
                    minRows='12'
                    placeholder={t('message')} />
                </Grid>
                <Grid item sx={{ marginTop: '30px', width: '100%' }}>
                  <Button
                    value={message.value}
                    sx={{
                      px: 3,
                      height: '50px',
                      background: '#000',
                      color: '#fff',
                      fontSize: '18px',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      borderRadius: '8px',
                      textTransform: 'none',
                      '&:hover': { background: '#2f2e41' }
                    }}>{t('become_a_driver')}
                  </Button>
                </Grid>
              </Grid>
            </Box> */}
          </>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center', px: { xs: 2.5, sm: 2.5, md: 2.5, lg: 0 }, border: 'black solid 0px' }}>

          <Box>
            <Typography
              sx={{
                fontSize: '17px',
                fontFamily: 'Montserrat',
                fontWeight: 400,
              }}
            >{t('how_can_i_call_you')}</Typography>
            <TextField
              id='standard-basic'
              variant='standard'
              value={name ?? ''}
              onChange={(e) => setName(e.target.value)}
              sx={{ width: { xs: '90vw', sm: '550px', md: '650px', lg: '700px' } }}
            >
            </TextField>

            <Typography
              sx={{
                fontSize: '17px',
                fontFamily: 'Montserrat',
                fontWeight: 400,
                mt: 2,
                color: 'black',
              }}
            >{'*' + t('your_phone_number') + ':'}
            </Typography>
            <TextField
              // id='standard-basic'
              variant='standard'
              value={phoneNumber ?? ''}
              onChange={(e) => setPhoneNumber(e.target.value)}
              error={phoneNumber.length === 0 || notANumber || phoneNumber.length < 6 || phoneNumber.length > 20}
              sx={{
                width: { xs: '90vw', sm: '550px', md: '650px', lg: '700px' },
                '.MuiInputBase-root.MuiInput-root': {
                  fontFamily: 'Montserrat !important',
                  fontWeight: '600 !important',
                  borderBottom:
                  (isDirty && phoneNumber.length === 0) ||
                    (isDirty && notANumber) ||
                    (isDirty && phoneNumber.length < 3) ||
                    (isDirty && phoneNumber.length > 20) ?
                    '1px solid red !important' :
                    '0px solid #7879f1 !important',
                },                
                '.MuiInputBase-root.MuiInput-root:after': {
                  borderBottom:
                    (isDirty && phoneNumber.length === 0) ||
                      (isDirty && notANumber) ||
                      (isDirty && phoneNumber.length < 3) ||
                      (isDirty && phoneNumber.length > 20) ?
                      '1px solid red !important' :
                      '0px solid #7879f1 !important'
                },
                '.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover:not(.Mui-disabled):before' : {
                  borderBottom:
                  (isDirty && phoneNumber.length === 0) ||
                  (isDirty && notANumber) ||
                  (isDirty && phoneNumber.length < 3) ||
                  (isDirty && phoneNumber.length > 20) ?
                  '2px solid red !important' :
                  '2px solid #7879f1 !important' 
                },
                '.MuiInputBase-root.MuiInput-root.Mui-focused:not(:hover):before' : {
                  borderBottom: 
                  (isDirty && phoneNumber.length === 0) ||
                  (isDirty && notANumber) ||
                  (isDirty && phoneNumber.length < 3) ||
                  (isDirty && phoneNumber.length > 20) ?
                  '2px solid red !important':
                  '2px solid #7879f1 !important'
                }
              }}
            />
            {((isDirty && phoneNumber.length === 0) && <Typography sx={{ mt: "5px", fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('enter_the_number')}</Typography>) ||
              ((isDirty && notANumber) && <Typography sx={{ mt: "5px", fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('only_numbers')}</Typography>) ||
              ((isDirty && phoneNumber.length < 3) && <Typography sx={{ mt: "5px", fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('min_symbols_three')}</Typography>) ||
              ((isDirty && phoneNumber.length > 20) && <Typography sx={{ mt: "5px", fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 600, color: 'red', lineHeight: '17px' }}>{t('max_symbols_twenty')}</Typography>)}


            <Typography
              sx={{
                mt: 2,
                fontSize: '17px',
                fontFamily: 'Montserrat',
                fontWeight: 400,
              }}
            >{t('message') + ':'}</Typography>
            <TextField
              multiline
              rows={3}
              id='standard-basic'
              variant='standard'
              value={message ?? ''}
              onChange={(e) => setMessage(e.target.value)}
              sx={{ width: { xs: '90vw', sm: '550px', md: '650px', lg: '700px' }, mt: 1 }}
            >
            </TextField>

            <Box sx={{ display: 'flex', justifyContent: 'start', mt: 3 }}>
              <Button
                onClick={SendMessage}
                sx={{
                  px: 3,
                  height: { xs: '9.5vw', sm: '50px' },
                  background: '#000',
                  color: loading ? '#000' : "#fff",
                  fontSize: { xs: '3vw', sm: '18px' },
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  borderRadius: '8px',
                  textTransform: 'none',
                  '&:hover': { background: '#2f2e41',
                  color: loading ? '#2f2e41' : "#fff"
                }
                }}
              >
                {loading && <CircularProgress size={!smUp ? "6vw" : "30px"} sx={{color: '#797af0', position: "absolute"}}/>}
                {t('send')}
              </Button>
              <Typography
                sx={{
                  textAlign: 'center',
                  mt: { xs: '2.3vw', sm: 2 },
                  ml: 2, fontSize:
                    { xs: '4vw', sm: '16px' },
                  fontFamily: 'Montserrat',
                  fontWeight: 400,
                }}>{t('or_contact_us')}</Typography>
            </Box>

            <Box sx={{
              height: { xs: '23vw', sm: '140px' },
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 8,
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontFamily: 'Montserrat',
                fontWeight: 600,
                letterSpacing: '10px',
                textTransform: 'uppercase',
                color: 'black',
              }}>	{t('contact')}
              </Typography>
              <Typography sx={{
                fontSize: { xs: '10.7vw', sm: '56px' },
                fontFamily: 'Montserrat',
                fontWeight: 800,
                letterSpacing: '0px',
                color: 'black',
              }}>	+373 78 222 268
              </Typography>
              <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                color: 'black',
              }}><EmailIcon sx={{ mr: '5px' }} />	support@letz.md
              </Typography>
            </Box>

          </Box>
        </Box>

        {!smUp && (
          <>
            {/* <Box sx={{ marginTop: '120px', textAlign: 'center' }}>
              <Typography sx={{
                color: '#1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '45px',
                lineHeight: '64px',
                fontWeight: '700',
                letterSpacing: -1.5
              }}>
                {t('do_you_want_to_be_a_Letz_driver')}
              </Typography>
            </Box> */}
            <Box sx={{ marginTop: '50px', mb: "-50px" }}>
              <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item>
                  <Box sx={{
                    minWidth: 200,
                    minHeight: '190px',
                    pb: 1,
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)',
                      boxShadow: '0px 0px 51px 0px #E4E4E4',
                      transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconBadge />
                      </Box>
                      <Typography component='p' sx={{ width: '100%', textAlign: 'center', whiteSpace: 'pre-line', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('with_all_social_packages')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{
                    minWidth: 200,
                    minHeight: '190px',
                    pb: 1,
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)', 
                      boxShadow: '0px 0px 51px 0px #E4E4E4', transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCar className='iconHover' />
                      </Box>
                      <Typography component='p' sx={{ width: '100%', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('the_opportunity_to_buy_a_car')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{
                    minWidth: 200,
                    minHeight: '190px',
                    pb: 1,
                    borderRadius: '8px',
                    boxShadow: '0px 0px 51px 0px #F7F7F7',
                    boxSizing: 'border-box',
                    // border: '2px solid transparent',
                    '&:hover': {
                      // border: '2px solid rgba(120, 121, 240, 0.3)',
                      boxShadow: '0px 0px 51px 0px #E4E4E4',
                      transition: 'ease-in-out .3s'
                    }
                  }}>
                    <Box sx={{ padding: '10px', marginTop: '20px', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', width: '50px', mb: 3, '&:hover': { cursor: 'pointer', color: '#7879f1;', transform: 'scale(1.1)', transition: 'ease-in-out .3s' } }}>
                        <IconCard className='iconHover' />
                      </Box>
                      <Typography component='p' sx={{ width: '100%', textAlign: 'center', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 600 }}>
                        {t('bonus_system')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* <Box sx={{ margin: '20px 0', padding: '20px' }}>
              <Typography sx={{
                textAlign: 'center',
                color: '1a1b1e',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                lineHeight: '25px',
                fontWeight: '700',
                letterSpacing: '0px'
              }}>
                {t('fill_out_this_form')}
              </Typography>           
            </Box> */}
          </>
        )}

      </Container>
    </>
  )
}

export default DriverFrom
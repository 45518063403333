const VisaPromo = ({ width, height }) => {
  // width="722"
  // height="442"
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 722 442"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15038_34725)">
        <rect width="722" height="441.864" fill="white" />
        <path
          opacity="0.18"
          d="M-21.1617 41.4807C-21.5508 32.0795 -21.0863 22.0906 -20.7407 11.5837C-19.5196 -28.5777 -1.09034 -64.6173 16.6339 -98.4337L101.466 -260.397C115.767 -287.672 130.819 -315.829 155.91 -333.378C170.56 -343.384 187.631 -349.641 205.786 -351.659C234.391 -355.171 265.568 -349.805 293.845 -339.138C340.101 -321.677 381.301 -291.784 423.909 -265.666C448.724 -250.45 474.428 -236.272 496.356 -216.89C518.284 -197.508 845.453 -218.424 849.077 -190.356C853.935 -152.626 956.593 -139.114 943.578 -106.858C929.852 -72.8409 783.062 243.921 781.5 280.998C851.5 264.998 603.999 147.997 566.5 158.997C529 169.999 409.393 218.845 369.71 218.917C342.328 218.962 314.772 212.073 289.586 198.886C259.982 183.364 240.5 178.499 203 178.499C138 178.499 46.1206 288.307 -12.0006 257.998C-42.3522 242.186 -20.1556 66.4848 -21.1617 41.4807Z"
          fill="#B8B8B8"
        />
        <path
          d="M624.714 110.689C620.108 108.177 618.014 110.271 616.325 108.177C614.635 106.083 620.946 100.625 608.368 95.1808C608.368 95.1808 607.531 83.4411 590.333 83.0223C573.135 82.6036 577.813 94.7043 573.149 97.6934C568.485 100.682 567.272 96.0183 562.666 98.9496C558.059 101.881 560.139 107.758 556.37 108.596C552.601 109.433 547.562 104.827 542.955 107.758C538.349 110.689 538.334 113.202 536.241 113.202C534.147 113.202 529.959 111.527 527.432 113.202C524.905 114.877 521.988 116.985 521.988 116.985H628.065C628.065 116.985 629.335 113.144 624.714 110.689Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M316.656 165.567C314.562 164.427 313.609 165.38 312.858 164.427C312.107 163.474 314.952 161.004 309.233 158.535C309.233 158.535 308.858 153.192 301.046 153.005C293.234 152.817 295.342 158.333 293.248 159.676C291.155 161.019 290.591 158.911 288.498 160.239C286.404 161.568 287.342 164.239 285.61 164.571C283.877 164.903 281.61 162.867 279.516 164.196C277.422 165.524 277.422 166.665 276.469 166.665C275.516 166.665 273.581 165.914 272.469 166.665C271.357 167.416 270 168.383 270 168.383H318.157C318.281 167.812 318.198 167.216 317.923 166.7C317.648 166.185 317.199 165.783 316.656 165.567Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M400.128 77.2768C397.24 75.7172 395.94 77.0168 394.886 75.7172C393.832 74.4176 397.774 70.9954 389.919 67.602C389.919 67.602 389.399 60.2664 378.67 60.0065C367.941 59.7466 370.815 67.342 367.927 69.1615C365.039 70.9809 364.274 68.1218 361.386 69.9557C358.498 71.7895 359.826 75.4429 357.458 75.9772C355.09 76.5114 351.971 73.6234 349.097 75.4429C346.224 77.2623 346.209 78.8507 344.909 78.8507C343.61 78.8507 340.982 77.7966 339.408 78.8507C337.834 79.9048 336 81.2044 336 81.2044H402.222C402.405 80.4073 402.294 79.571 401.909 78.8494C401.524 78.1277 400.892 77.5692 400.128 77.2768Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M427.711 386.023C427.711 386.023 447.739 388.001 457.891 400.463C457.891 400.463 468.62 386.023 487.883 386.369C492.61 380.686 495.625 373.776 496.575 366.445C497.525 359.114 496.371 351.664 493.248 344.964C490.124 338.264 485.161 332.589 478.936 328.603C472.71 324.616 465.48 322.481 458.088 322.448C450.695 322.414 443.446 324.483 437.184 328.412C430.923 332.342 425.908 337.971 422.724 344.642C419.54 351.314 418.318 358.753 419.201 366.092C420.084 373.432 423.035 380.369 427.711 386.095V386.023Z"
          fill="#C4C4C4"
        />
        <path
          opacity="0.5"
          d="M427.711 386.023C427.711 386.023 447.739 388.001 457.891 400.463C457.891 400.463 468.62 386.023 487.883 386.369C492.61 380.686 495.625 373.776 496.575 366.445C497.525 359.114 496.371 351.664 493.248 344.964C490.124 338.264 485.161 332.589 478.936 328.603C472.71 324.616 465.48 322.481 458.088 322.448C450.695 322.414 443.446 324.483 437.184 328.412C430.923 332.342 425.908 337.971 422.724 344.642C419.54 351.314 418.318 358.753 419.201 366.092C420.084 373.432 423.035 380.369 427.711 386.095V386.023Z"
          fill="white"
        />
        <path
          d="M457.225 325.301L456.561 425.702H459.94L457.225 325.301Z"
          fill="black"
        />
        <path
          d="M458.251 396.638C458.251 396.638 465.009 384.465 477.182 380.754C477.182 380.754 464.33 383.786 457.904 394.27L458.251 396.638Z"
          fill="black"
        />
        <path
          d="M458.251 362.154C458.251 362.154 465.009 349.981 477.182 346.27C477.182 346.27 464.33 349.302 457.904 359.785L458.251 362.154Z"
          fill="black"
        />
        <path
          d="M458.251 379.394C458.251 379.394 465.009 367.221 477.182 363.51C477.182 363.51 464.33 366.542 457.904 377.026L458.251 379.394Z"
          fill="black"
        />
        <path
          d="M457.243 370.947C457.243 370.947 450.716 358.774 438.977 355.062C438.977 355.062 451.381 358.109 457.575 368.593L457.243 370.947Z"
          fill="black"
        />
        <path
          d="M457.243 387.841C457.243 387.841 450.716 375.668 438.977 371.957C438.977 371.957 451.381 375.004 457.575 385.473L457.243 387.841Z"
          fill="black"
        />
        <path
          d="M522.046 378.413C522.046 378.413 545.915 380.767 558.016 395.568C558.016 395.568 570.795 378.428 593.755 378.774C599.363 372.006 602.934 363.784 604.05 355.065C605.167 346.347 603.784 337.49 600.062 329.527C596.341 321.564 590.434 314.822 583.029 310.086C575.624 305.35 567.026 302.815 558.236 302.776C549.446 302.737 540.826 305.196 533.38 309.866C525.933 314.536 519.966 321.226 516.174 329.156C512.382 337.085 510.921 345.929 511.96 354.658C513 363.386 516.497 371.639 522.046 378.457V378.413Z"
          fill="#C4C4C4"
        />
        <path
          opacity="0.5"
          d="M522.411 378.638C522.411 378.638 546.28 380.992 558.381 395.793C558.381 395.793 571.16 378.652 594.12 378.999C599.728 372.231 603.299 364.009 604.416 355.29C605.532 346.571 604.149 337.715 600.428 329.752C596.706 321.789 590.799 315.047 583.394 310.31C575.989 305.574 567.391 303.039 558.601 303C549.811 302.962 541.191 305.42 533.745 310.091C526.298 314.761 520.332 321.45 516.54 329.38C512.748 337.31 511.286 346.154 512.326 354.882C513.365 363.61 516.862 371.864 522.411 378.681V378.638Z"
          fill="white"
        />
        <path
          d="M557.225 306.055L556.416 425.705H560.459L557.225 306.055Z"
          fill="black"
        />
        <path
          d="M558.436 391.062C558.436 391.062 566.493 376.55 580.991 372.117C580.991 372.117 565.684 375.756 558.031 388.232L558.436 391.062Z"
          fill="black"
        />
        <path
          d="M558.436 349.966C558.436 349.966 566.493 335.454 580.991 331.035C580.991 331.035 565.684 334.66 558.031 347.15L558.436 349.966Z"
          fill="black"
        />
        <path
          d="M558.436 370.514C558.436 370.514 566.493 356.074 580.991 351.568C580.991 351.568 565.684 355.207 558.031 367.698L558.436 370.514Z"
          fill="black"
        />
        <path
          d="M557.236 360.435C557.236 360.435 549.467 345.995 535.475 341.504C535.475 341.504 550.247 345.128 557.626 357.619L557.236 360.435Z"
          fill="black"
        />
        <path
          d="M557.236 380.577C557.236 380.577 549.467 366.137 535.475 361.646C535.475 361.646 550.247 365.271 557.626 377.761L557.236 380.577Z"
          fill="black"
        />
        <path
          d="M618.131 392.233C618.131 392.233 635.026 393.908 643.589 404.377C646.666 400.651 650.531 397.652 654.906 395.598C659.28 393.543 664.055 392.482 668.888 392.493C672.86 387.701 675.389 381.88 676.181 375.706C676.973 369.533 675.995 363.261 673.36 357.622C670.726 351.983 666.544 347.209 661.301 343.855C656.058 340.5 649.971 338.704 643.747 338.676C637.523 338.648 631.419 340.388 626.146 343.695C620.872 347.001 616.647 351.737 613.962 357.352C611.276 362.967 610.241 369.229 610.976 375.41C611.712 381.59 614.188 387.434 618.117 392.262L618.131 392.233Z"
          fill="#C4C4C4"
        />
        <path
          opacity="0.5"
          d="M618.131 392.233C618.131 392.233 635.026 393.908 643.589 404.377C646.666 400.651 650.531 397.652 654.906 395.598C659.28 393.543 664.055 392.482 668.888 392.493C672.86 387.701 675.389 381.88 676.181 375.706C676.973 369.533 675.995 363.261 673.36 357.622C670.726 351.983 666.544 347.209 661.301 343.855C656.058 340.5 649.971 338.704 643.747 338.676C637.523 338.648 631.419 340.388 626.146 343.695C620.872 347.001 616.647 351.737 613.962 357.352C611.276 362.967 610.241 369.229 610.976 375.41C611.712 381.59 614.188 387.434 618.117 392.262L618.131 392.233Z"
          fill="white"
        />
        <path
          d="M643.026 341.016L642.463 425.706H645.308L643.026 341.016Z"
          fill="black"
        />
        <path
          d="M643.892 401.184C643.892 401.184 649.582 390.917 659.776 387.783C659.776 387.783 648.946 390.339 643.531 399.191L643.892 401.184Z"
          fill="black"
        />
        <path
          d="M643.892 372.092C643.892 372.092 649.582 361.825 659.776 358.691C659.776 358.691 648.946 361.262 643.531 370.099L643.892 372.092Z"
          fill="black"
        />
        <path
          d="M643.892 386.643C643.892 386.643 649.582 376.376 659.776 373.229C659.776 373.229 648.946 375.799 643.531 384.636L643.892 386.643Z"
          fill="black"
        />
        <path
          d="M643.04 379.512C643.04 379.512 637.539 369.245 627.633 366.111C627.633 366.111 638.087 368.667 643.315 377.519L643.04 379.512Z"
          fill="black"
        />
        <path
          d="M643.04 393.762C643.04 393.762 637.539 383.495 627.633 380.361C627.633 380.361 638.087 382.932 643.315 391.769L643.04 393.762Z"
          fill="black"
        />
        <path
          d="M78.9416 386.023C78.9416 386.023 98.9698 388.001 109.121 400.463C109.121 400.463 119.85 386.023 139.113 386.369C143.841 380.686 146.855 373.776 147.805 366.445C148.755 359.114 147.601 351.664 144.478 344.964C141.355 338.264 136.391 332.589 130.166 328.603C123.941 324.616 116.71 322.481 109.318 322.448C101.926 322.414 94.6763 324.483 88.4149 328.412C82.1535 332.342 77.1384 337.971 73.9543 344.642C70.7703 351.314 69.5485 358.753 70.4315 366.092C71.3145 373.432 74.2659 380.369 78.9416 386.095V386.023Z"
          fill="#C4C4C4"
        />
        <path
          opacity="0.5"
          d="M78.9416 386.023C78.9416 386.023 98.9698 388.001 109.121 400.463C109.121 400.463 119.85 386.023 139.113 386.369C143.841 380.686 146.855 373.776 147.805 366.445C148.755 359.114 147.601 351.664 144.478 344.964C141.355 338.264 136.391 332.589 130.166 328.603C123.941 324.616 116.71 322.481 109.318 322.448C101.926 322.414 94.6763 324.483 88.4149 328.412C82.1535 332.342 77.1384 337.971 73.9543 344.642C70.7703 351.314 69.5485 358.753 70.4315 366.092C71.3145 373.432 74.2659 380.369 78.9416 386.095V386.023Z"
          fill="white"
        />
        <path
          d="M108.458 325.301L107.779 425.702H111.158L108.458 325.301Z"
          fill="black"
        />
        <path
          d="M109.467 396.638C109.467 396.638 116.239 384.465 128.398 380.754C128.398 380.754 115.561 383.786 109.135 394.27L109.467 396.638Z"
          fill="black"
        />
        <path
          d="M109.467 362.154C109.467 362.154 116.239 349.981 128.398 346.27C128.398 346.27 115.561 349.302 109.135 359.785L109.467 362.154Z"
          fill="black"
        />
        <path
          d="M109.467 379.394C109.467 379.394 116.239 367.221 128.398 363.51C128.398 363.51 115.561 366.542 109.135 377.026L109.467 379.394Z"
          fill="black"
        />
        <path
          d="M108.472 370.947C108.472 370.947 101.945 358.774 90.2051 355.062C90.2051 355.062 102.595 358.109 108.804 368.593L108.472 370.947Z"
          fill="black"
        />
        <path
          d="M108.472 387.841C108.472 387.841 101.945 375.668 90.2051 371.957C90.2051 371.957 102.595 375.004 108.804 385.473L108.472 387.841Z"
          fill="black"
        />
        <path
          d="M173.279 378.414C173.279 378.414 197.149 380.768 209.249 395.569C209.249 395.569 222.029 378.429 244.988 378.775C250.595 372.007 254.163 363.785 255.278 355.067C256.393 346.349 255.009 337.494 251.287 329.532C247.564 321.57 241.657 314.829 234.253 310.094C226.848 305.359 218.251 302.825 209.463 302.786C200.674 302.747 192.055 305.205 184.609 309.874C177.163 314.544 171.196 321.232 167.403 329.16C163.611 337.089 162.148 345.931 163.186 354.659C164.224 363.386 167.719 371.64 173.265 378.458L173.279 378.414Z"
          fill="#C4C4C4"
        />
        <path
          opacity="0.5"
          d="M173.279 378.414C173.279 378.414 197.149 380.768 209.249 395.569C209.249 395.569 222.029 378.429 244.988 378.775C250.595 372.007 254.163 363.785 255.278 355.067C256.393 346.349 255.009 337.494 251.287 329.532C247.564 321.57 241.657 314.829 234.253 310.094C226.848 305.359 218.251 302.825 209.463 302.786C200.674 302.747 192.055 305.205 184.609 309.874C177.163 314.544 171.196 321.232 167.403 329.16C163.611 337.089 162.148 345.931 163.186 354.659C164.224 363.386 167.719 371.64 173.265 378.458L173.279 378.414Z"
          fill="white"
        />
        <path
          d="M208.455 306.055L207.646 425.705H211.675L208.455 306.055Z"
          fill="black"
        />
        <path
          d="M209.668 391.062C209.668 391.062 217.726 376.55 232.223 372.117C232.223 372.117 216.917 375.756 209.264 388.232L209.668 391.062Z"
          fill="black"
        />
        <path
          d="M209.668 349.966C209.668 349.966 217.726 335.454 232.223 331.035C232.223 331.035 216.917 334.66 209.264 347.15L209.668 349.966Z"
          fill="black"
        />
        <path
          d="M209.668 370.514C209.668 370.514 217.726 356.074 232.223 351.568C232.223 351.568 216.917 355.207 209.264 367.698L209.668 370.514Z"
          fill="black"
        />
        <path
          d="M208.467 360.435C208.467 360.435 200.698 345.995 186.691 341.504C186.691 341.504 201.464 345.128 208.857 357.619L208.467 360.435Z"
          fill="black"
        />
        <path
          d="M208.467 380.577C208.467 380.577 200.698 366.137 186.691 361.646C186.691 361.646 201.464 365.271 208.857 377.761L208.467 380.577Z"
          fill="black"
        />
        <path
          d="M295.108 372.092C295.108 372.092 300.812 361.825 311.079 358.691C311.079 358.691 300.249 361.262 294.834 370.099L295.108 372.092Z"
          fill="black"
        />
        <path
          d="M295.108 386.643C295.108 386.643 300.812 376.376 311.079 373.229C311.079 373.229 300.249 375.799 294.834 384.636L295.108 386.643Z"
          fill="black"
        />
        <path
          d="M294.269 379.512C294.269 379.512 288.767 369.245 278.861 366.111C278.861 366.111 289.316 368.667 294.543 377.519L294.269 379.512Z"
          fill="black"
        />
        <path
          d="M294.269 393.762C294.269 393.762 288.767 383.495 278.861 380.361C278.861 380.361 289.316 382.932 294.543 391.769L294.269 393.762Z"
          fill="white"
        />
        <path
          d="M494.714 424.668C515.497 419.82 528.416 399.042 523.568 378.259C518.72 357.476 497.942 344.558 477.159 349.406C456.375 354.254 443.457 375.032 448.305 395.815C453.153 416.598 473.931 429.516 494.714 424.668Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M242.627 414.363C257.718 399.272 257.718 374.806 242.627 359.715C227.537 344.625 203.07 344.625 187.98 359.715C172.889 374.806 172.889 399.272 187.98 414.363C203.07 429.453 227.537 429.453 242.627 414.363Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M459.871 313.81C459.871 313.81 449.619 307.398 402.805 292.005C355.99 276.612 325.839 271.471 279.675 272.771C233.51 274.071 171.288 291.37 159.75 295.875C148.213 300.38 143.072 301.651 145.643 304.842C148.213 308.034 162.306 319.6 160.386 324.726C158.465 329.852 127.073 358.111 125.109 362.545C123.145 366.978 131.535 382.428 132.805 386.284C134.076 390.139 141.152 391.41 146.913 392.06C152.675 392.71 563.132 392.06 563.132 392.06C563.132 392.06 568.908 385.013 567.623 376.032C566.338 367.05 564.966 355.209 559.175 347.628C554.497 341.477 549.659 334.993 537.515 333.708C529.819 332.885 461.806 314.459 459.871 313.81Z"
          fill="white"
        />
        <mask
          id="mask0_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="124"
          y="272"
          width="444"
          height="121"
        >
          <path
            d="M459.869 313.808C459.869 313.808 449.617 307.396 402.803 292.003C355.988 276.61 325.837 271.47 279.673 272.769C233.508 274.069 171.286 291.368 159.749 295.873C148.211 300.378 143.07 301.649 145.641 304.84C148.211 308.032 162.304 319.598 160.384 324.724C158.463 329.85 127.071 358.11 125.107 362.543C123.143 366.976 131.533 382.426 132.803 386.282C134.074 390.137 141.15 391.408 146.911 392.058C152.673 392.708 563.13 392.058 563.13 392.058C563.13 392.058 568.906 385.011 567.621 376.03C566.336 367.048 564.964 355.207 559.173 347.626C554.495 341.475 549.657 334.991 537.513 333.706C529.817 332.883 461.804 314.457 459.869 313.808Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_15038_34725)">
          <path
            d="M459.869 313.808C459.869 313.808 449.617 307.396 402.803 292.003C355.988 276.61 325.837 271.47 279.673 272.769C233.508 274.069 171.286 291.368 159.749 295.873C148.211 300.378 143.07 301.649 145.641 304.84C148.211 308.032 162.304 319.598 160.384 324.724C158.463 329.85 127.071 358.11 125.107 362.543C123.143 366.976 131.533 382.426 132.803 386.282C134.074 390.137 141.15 391.408 146.911 392.058C152.673 392.708 563.13 392.058 563.13 392.058C563.13 392.058 568.906 385.011 567.621 376.03C566.336 367.048 564.964 355.207 559.173 347.626C554.495 341.475 549.657 334.991 537.513 333.706C529.817 332.883 461.804 314.457 459.869 313.808Z"
            fill="white"
          />
          <mask
            id="mask1_15038_34725"
            //style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="124"
            y="272"
            width="444"
            height="121"
          >
            <path
              d="M459.869 313.806C459.869 313.806 449.617 307.394 402.803 292.001C355.988 276.608 325.837 271.468 279.673 272.767C233.508 274.067 171.286 291.366 159.749 295.871C148.211 300.376 143.07 301.647 145.641 304.838C148.211 308.03 162.304 319.596 160.384 324.722C158.463 329.848 127.071 358.108 125.107 362.541C123.143 366.974 131.533 382.425 132.803 386.28C134.074 390.136 141.15 391.406 146.911 392.056C152.673 392.706 563.13 392.056 563.13 392.056C563.13 392.056 568.906 385.009 567.621 376.028C566.336 367.046 564.964 355.205 559.173 347.624C554.495 341.473 549.657 334.989 537.513 333.704C529.817 332.881 461.804 314.455 459.869 313.806Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_15038_34725)">
            <path
              opacity="0.08"
              d="M459.869 313.806C459.869 313.806 449.617 307.394 402.803 292.001C355.988 276.608 325.837 271.468 279.673 272.767C233.508 274.067 171.286 291.366 159.749 295.871C148.211 300.376 143.07 301.647 145.641 304.838C148.211 308.03 162.304 319.596 160.384 324.722C158.463 329.848 127.071 358.108 125.107 362.541C123.143 366.974 131.533 382.425 132.803 386.28C134.074 390.136 141.15 391.406 146.911 392.056C152.673 392.706 563.13 392.056 563.13 392.056C563.13 392.056 568.906 385.009 567.621 376.028C566.336 367.046 564.964 355.205 559.173 347.624C554.495 341.473 549.657 334.989 537.513 333.704C529.817 332.881 461.804 314.455 459.869 313.806Z"
              fill="black"
            />
          </g>
        </g>
        <path
          d="M459.871 313.81C459.871 313.81 449.619 307.398 402.805 292.005C355.99 276.612 325.839 271.471 279.675 272.771C233.51 274.071 171.288 291.37 159.75 295.875C148.213 300.38 143.072 301.651 145.643 304.842C148.213 308.034 162.306 319.6 160.386 324.726C158.465 329.852 127.073 358.111 125.109 362.545C123.145 366.978 131.535 382.428 132.805 386.284C134.076 390.139 141.152 391.41 146.913 392.06C152.675 392.71 563.132 392.06 563.132 392.06C563.132 392.06 568.908 385.013 567.623 376.032C566.338 367.05 564.966 355.209 559.175 347.628C554.497 341.477 549.659 334.993 537.515 333.708C529.819 332.885 461.806 314.459 459.871 313.81Z"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M563.131 358.703H538.756V365.114H563.131V358.703Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M527.059 347.615H530.943C533.428 347.61 535.857 348.351 537.916 349.741C539.975 351.131 541.57 353.107 542.495 355.413L546.408 365.117H533.701L527.059 347.615Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M562.83 354.168C562.685 354.572 562.555 354.977 562.44 355.41L559.826 365.114H565.92C565.246 361.374 564.211 357.708 562.83 354.168Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M459.871 313.811C459.871 313.811 449.619 307.4 402.804 292.007C392.104 288.498 382.3 285.524 372.957 283.025H339.947L332.9 284.96L427.814 313.84L459.871 313.811Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M114.223 303.529V346.849C114.223 346.849 140.59 325.189 137.327 319.023C134.063 312.858 114.223 303.529 114.223 303.529Z"
          fill="#7879F1"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M427.814 313.81C427.814 313.81 417.547 307.398 370.733 292.005C323.918 276.612 293.782 271.471 247.603 272.771C201.424 274.071 139.216 291.37 127.751 295.875C116.286 300.38 111.073 301.651 113.643 304.842C116.213 308.034 130.321 319.6 128.386 324.726C126.451 329.852 94.9722 358.111 93.0517 362.545C91.1312 366.978 99.463 382.428 100.748 386.284C102.033 390.139 109.08 391.41 114.856 392.06C120.632 392.71 531.06 392.06 531.06 392.06C531.06 392.06 536.836 385.013 535.551 376.032C534.266 367.05 532.836 355.223 527.06 347.614C522.382 341.462 517.53 334.979 505.4 333.693C497.762 332.885 429.735 314.459 427.814 313.81Z"
          fill="white"
        />
        <mask
          id="mask2_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="92"
          y="272"
          width="444"
          height="121"
        >
          <path
            d="M427.816 313.808C427.816 313.808 417.549 307.396 370.735 292.003C323.92 276.61 293.784 271.47 247.605 272.769C201.426 274.069 139.218 291.368 127.753 295.873C116.288 300.378 111.075 301.649 113.645 304.84C116.215 308.032 130.323 319.598 128.388 324.724C126.453 329.85 94.9741 358.11 93.0536 362.543C91.1331 366.976 99.465 382.426 100.75 386.282C102.035 390.137 109.082 391.408 114.858 392.058C120.634 392.708 531.062 392.058 531.062 392.058C531.062 392.058 536.838 385.011 535.553 376.03C534.268 367.048 532.838 355.222 527.062 347.612C522.384 341.46 517.532 334.977 505.402 333.691C497.763 332.883 429.737 314.457 427.816 313.808Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_15038_34725)">
          <path
            opacity="0.08"
            d="M527.063 347.612C522.384 341.46 517.533 334.977 505.403 333.692C503.381 333.475 497.129 332.031 488.999 330.01C450.242 329.62 428.654 345.287 370.75 350.601C295.344 357.503 243.173 338.659 180.33 331.107C154.121 327.973 132.967 331.829 117.184 337.374C107.292 347.366 94.2529 359.713 93.04 362.543C91.1194 367.034 99.4513 382.427 100.736 386.282C102.022 390.138 109.068 391.408 114.844 392.058C120.62 392.708 531.048 392.058 531.048 392.058C531.048 392.058 536.824 385.012 535.539 376.03C534.254 367.048 532.839 355.222 527.063 347.612Z"
            fill="black"
          />
        </g>
        <path
          d="M427.814 313.81C427.814 313.81 417.547 307.398 370.733 292.005C323.918 276.612 293.782 271.471 247.603 272.771C201.424 274.071 139.216 291.37 127.751 295.875C116.286 300.38 111.073 301.651 113.643 304.842C116.213 308.034 130.321 319.6 128.386 324.726C126.451 329.852 94.9722 358.111 93.0517 362.545C91.1312 366.978 99.463 382.428 100.748 386.284C102.033 390.139 109.08 391.41 114.856 392.06C120.632 392.71 531.06 392.06 531.06 392.06C531.06 392.06 536.836 385.013 535.551 376.032C534.266 367.05 532.836 355.223 527.06 347.614C522.382 341.462 517.53 334.979 505.4 333.693C497.762 332.885 429.735 314.459 427.814 313.81Z"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.6"
          d="M185.281 290.156L210.941 317.26H399.556V311.108C399.556 311.108 360.901 295.932 320.136 288.004C279.372 280.077 269.192 280.784 250.824 281.131C232.457 281.477 185.281 290.156 185.281 290.156Z"
          fill="#8F8F8F"
        />
        <mask
          id="mask3_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="184"
          y="281"
          width="216"
          height="37"
        >
          <path
            d="M184.988 290.224L210.648 317.328H399.263V311.177C399.263 311.177 360.608 296 319.843 288.073C279.079 280.145 268.899 280.853 250.531 281.199C232.164 281.546 184.988 290.224 184.988 290.224Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_15038_34725)">
          <path
            d="M277.251 305.405C253.242 299.923 228.125 301.996 205.34 311.34L210.943 317.261H399.558V311.109C399.558 311.109 393.305 308.654 383.168 305.203C361.956 313.347 319.878 314.791 277.251 305.405Z"
            fill="black"
            stroke="black"
            strokeWidth="1.444"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M185.281 290.156L210.941 317.26H399.556V311.108C399.556 311.108 360.901 295.932 320.136 288.004C279.372 280.077 269.192 280.784 250.824 281.131C232.457 281.477 185.281 290.156 185.281 290.156Z"
          stroke="black"
          strokeWidth="5.776"
          strokeMiterlimit="10"
        />
        <path
          d="M180.387 424.703C201.175 419.876 214.115 399.112 209.289 378.324C204.463 357.536 183.698 344.596 162.91 349.422C142.122 354.248 129.182 375.012 134.008 395.801C138.834 416.589 159.599 429.529 180.387 424.703Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M171.692 359.152C166.176 359.152 160.783 360.789 156.196 363.855C151.61 366.92 148.036 371.278 145.927 376.375C143.818 381.473 143.268 387.082 144.348 392.492C145.427 397.902 148.087 402.87 151.991 406.768C155.895 410.666 160.867 413.318 166.279 414.389C171.691 415.46 177.299 414.902 182.393 412.785C187.487 410.668 191.839 407.087 194.898 402.496C197.956 397.905 199.584 392.509 199.576 386.993C199.561 379.606 196.617 372.527 191.389 367.308C186.162 362.089 179.079 359.156 171.692 359.152ZM190.464 381.448L174.219 384.495L178.826 368.827C181.589 369.917 184.069 371.618 186.08 373.804C188.09 375.99 189.58 378.604 190.435 381.448H190.464ZM173.136 385.635V388.437H170.349V385.635H173.136ZM166.162 368.307L169.209 384.552L153.527 379.931C154.602 377.173 156.288 374.694 158.459 372.681C160.63 370.667 163.229 369.172 166.061 368.307H166.162ZM153.007 392.624L169.252 389.577L164.617 405.245C161.84 404.169 159.347 402.472 157.327 400.285C155.308 398.098 153.815 395.477 152.964 392.624H153.007ZM177.324 405.765L174.277 389.534L189.915 394.213C188.821 396.969 187.115 399.44 184.926 401.442C182.738 403.443 180.123 404.921 177.28 405.765H177.324Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M180.342 386.99C180.342 385.277 179.834 383.601 178.882 382.177C177.93 380.752 176.576 379.641 174.993 378.986C173.41 378.33 171.668 378.158 169.987 378.493C168.307 378.827 166.763 379.652 165.551 380.864C164.34 382.075 163.514 383.619 163.18 385.3C162.846 386.981 163.017 388.723 163.673 390.306C164.329 391.889 165.439 393.242 166.864 394.194C168.289 395.146 169.964 395.654 171.678 395.654C173.976 395.654 176.179 394.741 177.804 393.117C179.429 391.492 180.342 389.288 180.342 386.99Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M470.94 414.363C486.03 399.272 486.03 374.806 470.94 359.715C455.849 344.625 431.383 344.625 416.292 359.715C401.202 374.806 401.202 399.272 416.292 414.363C431.383 429.453 455.849 429.453 470.94 414.363Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M443.61 359.152C438.093 359.152 432.701 360.789 428.114 363.855C423.528 366.92 419.954 371.278 417.845 376.375C415.736 381.473 415.186 387.082 416.266 392.492C417.345 397.902 420.005 402.87 423.909 406.768C427.813 410.666 432.785 413.318 438.197 414.389C443.609 415.46 449.217 414.902 454.311 412.785C459.405 410.668 463.757 407.087 466.816 402.496C469.874 397.905 471.502 392.509 471.494 386.993C471.479 379.606 468.534 372.527 463.307 367.308C458.08 362.089 450.997 359.156 443.61 359.152ZM462.382 381.448L446.152 384.495L450.758 368.827C453.517 369.918 455.993 371.62 457.999 373.806C460.005 375.992 461.489 378.605 462.339 381.448H462.382ZM445.054 385.635V388.437H442.253V385.635H445.054ZM438.065 368.307L441.112 384.552L425.445 379.931C426.523 377.167 428.215 374.684 430.394 372.67C432.573 370.656 435.181 369.164 438.022 368.307H438.065ZM424.925 392.624L441.17 389.577L436.549 405.245C433.771 404.17 431.275 402.474 429.253 400.286C427.231 398.099 425.735 395.478 424.881 392.624H424.925ZM449.242 405.765L446.195 389.534L461.819 394.213C460.728 396.968 459.025 399.44 456.838 401.441C454.652 403.442 452.04 404.921 449.198 405.765H449.242Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M452.264 386.99C452.264 385.277 451.755 383.601 450.803 382.177C449.851 380.752 448.498 379.641 446.915 378.986C445.332 378.33 443.59 378.158 441.909 378.493C440.229 378.827 438.685 379.652 437.473 380.864C436.262 382.075 435.436 383.619 435.102 385.3C434.768 386.981 434.939 388.723 435.595 390.306C436.251 391.889 437.361 393.242 438.786 394.194C440.211 395.146 441.886 395.654 443.6 395.654C445.897 395.654 448.101 394.741 449.726 393.117C451.351 391.492 452.264 389.288 452.264 386.99Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M527.061 347.612C527.061 347.612 498.296 346.168 497.011 347.612C495.726 349.056 506.628 360.608 510.483 363.193C513.53 365.229 527.884 364.839 533.688 364.637C532.519 358.428 530.627 352.233 527.061 347.612Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M275.287 281.321C273.395 281.191 271.619 281.105 269.886 281.047L265.049 317.147H277.872L275.287 281.321Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M10.7689 424.668C31.5521 419.82 44.4702 399.042 39.6223 378.259C34.7745 357.476 13.9964 344.558 -6.78675 349.406C-27.5699 354.254 -40.488 375.032 -35.6401 395.815C-30.7923 416.598 -10.0142 429.516 10.7689 424.668Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M-24.0739 313.81C-24.0739 313.81 -34.3263 307.398 -81.1408 292.005C-127.955 276.612 -158.106 271.471 -204.271 272.771C-250.435 274.071 -312.657 291.37 -324.195 295.875C-335.732 300.38 -340.873 301.651 -338.303 304.842C-335.732 308.034 -321.639 319.6 -323.559 324.726C-325.48 329.852 -356.873 358.111 -358.836 362.545C-360.8 366.978 -352.411 382.428 -351.14 386.284C-349.869 390.139 -342.794 391.41 -337.032 392.06C-331.27 392.71 79.1865 392.06 79.1865 392.06C79.1865 392.06 84.9625 385.013 83.6774 376.032C82.3922 367.05 81.0204 355.209 75.23 347.628C70.5514 341.477 65.714 334.993 53.57 333.708C45.8734 332.885 -22.1389 314.459 -24.0739 313.81Z"
          fill="white"
        />
        <mask
          id="mask4_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-360"
          y="272"
          width="444"
          height="121"
        >
          <path
            d="M-24.0719 313.808C-24.0719 313.808 -34.3243 307.396 -81.1388 292.003C-127.953 276.61 -158.104 271.47 -204.269 272.769C-250.433 274.069 -312.655 291.368 -324.193 295.873C-335.73 300.378 -340.871 301.649 -338.301 304.84C-335.73 308.032 -321.637 319.598 -323.558 324.724C-325.478 329.85 -356.871 358.11 -358.834 362.543C-360.798 366.976 -352.409 382.426 -351.138 386.282C-349.867 390.137 -342.792 391.408 -337.03 392.058C-331.268 392.708 79.1885 392.058 79.1885 392.058C79.1885 392.058 84.9645 385.011 83.6793 376.03C82.3942 367.048 81.0224 355.207 75.2319 347.626C70.5534 341.475 65.716 334.991 53.5719 333.706C45.8754 332.883 -22.137 314.457 -24.0719 313.808Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_15038_34725)">
          <path
            d="M-24.0719 313.808C-24.0719 313.808 -34.3243 307.396 -81.1388 292.003C-127.953 276.61 -158.104 271.47 -204.269 272.769C-250.433 274.069 -312.655 291.368 -324.193 295.873C-335.73 300.378 -340.871 301.649 -338.301 304.84C-335.73 308.032 -321.637 319.598 -323.558 324.724C-325.478 329.85 -356.871 358.11 -358.834 362.543C-360.798 366.976 -352.409 382.426 -351.138 386.282C-349.867 390.137 -342.792 391.408 -337.03 392.058C-331.268 392.708 79.1885 392.058 79.1885 392.058C79.1885 392.058 84.9645 385.011 83.6793 376.03C82.3942 367.048 81.0224 355.207 75.2319 347.626C70.5534 341.475 65.716 334.991 53.5719 333.706C45.8754 332.883 -22.137 314.457 -24.0719 313.808Z"
            fill="white"
          />
          <mask
            id="mask5_15038_34725"
            //style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="-360"
            y="272"
            width="444"
            height="121"
          >
            <path
              d="M-24.07 313.806C-24.07 313.806 -34.3224 307.394 -81.1369 292.001C-127.951 276.608 -158.102 271.468 -204.267 272.767C-250.431 274.067 -312.653 291.366 -324.191 295.871C-335.728 300.376 -340.869 301.647 -338.299 304.838C-335.728 308.03 -321.635 319.596 -323.556 324.722C-325.476 329.848 -356.869 358.108 -358.832 362.541C-360.796 366.974 -352.407 382.425 -351.136 386.28C-349.865 390.136 -342.79 391.406 -337.028 392.056C-331.267 392.706 79.1905 392.056 79.1905 392.056C79.1905 392.056 84.9664 385.009 83.6813 376.028C82.3961 367.046 81.0243 355.205 75.2339 347.624C70.5553 341.473 65.7179 334.989 53.5739 333.704C45.8773 332.881 -22.135 314.455 -24.07 313.806Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_15038_34725)">
            <path
              opacity="0.08"
              d="M-24.07 313.806C-24.07 313.806 -34.3224 307.394 -81.1369 292.001C-127.951 276.608 -158.102 271.468 -204.267 272.767C-250.431 274.067 -312.653 291.366 -324.191 295.871C-335.728 300.376 -340.869 301.647 -338.299 304.838C-335.728 308.03 -321.635 319.596 -323.556 324.722C-325.476 329.848 -356.869 358.108 -358.832 362.541C-360.796 366.974 -352.407 382.425 -351.136 386.28C-349.865 390.136 -342.79 391.406 -337.028 392.056C-331.267 392.706 79.1905 392.056 79.1905 392.056C79.1905 392.056 84.9664 385.009 83.6813 376.028C82.3961 367.046 81.0243 355.205 75.2339 347.624C70.5553 341.473 65.7179 334.989 53.5739 333.704C45.8773 332.881 -22.135 314.455 -24.07 313.806Z"
              fill="black"
            />
          </g>
        </g>
        <path
          d="M-24.0739 313.81C-24.0739 313.81 -34.3263 307.398 -81.1408 292.005C-127.955 276.612 -158.106 271.471 -204.271 272.771C-250.435 274.071 -312.657 291.37 -324.195 295.875C-335.732 300.38 -340.873 301.651 -338.303 304.842C-335.732 308.034 -321.639 319.6 -323.559 324.726C-325.48 329.852 -356.873 358.111 -358.836 362.545C-360.8 366.978 -352.411 382.428 -351.14 386.284C-349.869 390.139 -342.794 391.41 -337.032 392.06C-331.27 392.71 79.1865 392.06 79.1865 392.06C79.1865 392.06 84.9625 385.013 83.6774 376.032C82.3922 367.05 81.0204 355.209 75.23 347.628C70.5514 341.477 65.714 334.993 53.57 333.708C45.8734 332.885 -22.1389 314.459 -24.0739 313.81Z"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M79.1853 358.703H54.8105V365.114H79.1853V358.703Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M43.1133 347.615H46.9976C49.4823 347.61 51.9113 348.351 53.9707 349.741C56.0301 351.131 57.6251 353.107 58.5496 355.413L62.4629 365.117H49.7557L43.1133 347.615Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M78.8863 354.168C78.7419 354.572 78.612 354.977 78.4964 355.41L75.8828 365.114H81.9765C81.3028 361.374 80.2679 357.708 78.8863 354.168Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M-56.1312 313.81C-56.1312 313.81 -66.398 307.398 -113.212 292.005C-160.027 276.612 -190.163 271.471 -236.342 272.771C-282.521 274.071 -344.729 291.37 -356.194 295.875C-367.66 300.38 -372.873 301.651 -370.302 304.842C-367.732 308.034 -353.624 319.6 -355.559 324.726C-357.494 329.852 -388.973 358.111 -390.894 362.545C-392.814 366.978 -384.482 382.428 -383.197 386.284C-381.912 390.139 -374.865 391.41 -369.089 392.06C-363.313 392.71 47.1148 392.06 47.1148 392.06C47.1148 392.06 52.8908 385.013 51.6057 376.032C50.3205 367.05 48.891 355.223 43.115 347.614C38.4364 341.462 33.5846 334.979 21.455 333.693C13.8162 332.885 -54.2107 314.459 -56.1312 313.81Z"
          fill="white"
        />
        <mask
          id="mask6_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-392"
          y="272"
          width="444"
          height="121"
        >
          <path
            d="M-56.1312 313.808C-56.1312 313.808 -66.398 307.396 -113.212 292.003C-160.027 276.61 -190.163 271.47 -236.342 272.769C-282.521 274.069 -344.729 291.368 -356.194 295.873C-367.66 300.378 -372.873 301.649 -370.302 304.84C-367.732 308.032 -353.624 319.598 -355.559 324.724C-357.494 329.85 -388.973 358.11 -390.894 362.543C-392.814 366.976 -384.482 382.426 -383.197 386.282C-381.912 390.137 -374.865 391.408 -369.089 392.058C-363.313 392.708 47.1148 392.058 47.1148 392.058C47.1148 392.058 52.8908 385.011 51.6057 376.03C50.3205 367.048 48.891 355.222 43.115 347.612C38.4364 341.46 33.5846 334.977 21.455 333.691C13.8162 332.883 -54.2107 314.457 -56.1312 313.808Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_15038_34725)">
          <path
            opacity="0.08"
            d="M43.1177 347.612C38.4391 341.46 33.5873 334.977 21.4577 333.692C19.4361 333.475 13.1836 332.031 5.05388 330.01C-33.7031 329.62 -55.2909 345.287 -113.195 350.601C-188.601 357.503 -240.773 338.659 -303.616 331.107C-329.824 327.973 -350.979 331.829 -366.762 337.374C-376.653 347.366 -389.692 359.713 -390.905 362.543C-392.826 367.034 -384.494 382.427 -383.209 386.282C-381.924 390.138 -374.877 391.408 -369.101 392.058C-363.325 392.708 47.1031 392.058 47.1031 392.058C47.1031 392.058 52.8791 385.012 51.594 376.03C50.3088 367.048 48.8937 355.222 43.1177 347.612Z"
            fill="black"
          />
        </g>
        <path
          d="M-56.1312 313.81C-56.1312 313.81 -66.398 307.398 -113.212 292.005C-160.027 276.612 -190.163 271.471 -236.342 272.771C-282.521 274.071 -344.729 291.37 -356.194 295.875C-367.66 300.38 -372.873 301.651 -370.302 304.842C-367.732 308.034 -353.624 319.6 -355.559 324.726C-357.494 329.852 -388.973 358.111 -390.894 362.545C-392.814 366.978 -384.482 382.428 -383.197 386.284C-381.912 390.139 -374.865 391.41 -369.089 392.06C-363.313 392.71 47.1148 392.06 47.1148 392.06C47.1148 392.06 52.8908 385.013 51.6057 376.032C50.3205 367.05 48.891 355.223 43.115 347.614C38.4364 341.462 33.5846 334.979 21.455 333.693C13.8162 332.885 -54.2107 314.459 -56.1312 313.81Z"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M-13.0037 414.363C2.08668 399.272 2.08668 374.806 -13.0037 359.715C-28.0942 344.625 -52.5606 344.625 -67.651 359.715C-82.7414 374.806 -82.7414 399.272 -67.651 414.363C-52.5606 429.453 -28.0942 429.453 -13.0037 414.363Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M43.1152 347.612C43.1152 347.612 14.3507 346.168 13.0656 347.612C11.7804 349.056 22.6826 360.608 26.5381 363.193C29.5849 365.229 43.9383 364.839 49.7432 364.637C48.5735 358.428 46.6819 352.233 43.1152 347.612Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M725.641 414.363C740.731 399.272 740.731 374.806 725.641 359.715C710.55 344.625 686.084 344.625 670.994 359.715C655.903 374.806 655.903 399.272 670.994 414.363C686.084 429.453 710.55 429.453 725.641 414.363Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M942.883 313.81C942.883 313.81 932.631 307.398 885.816 292.005C839.002 276.612 808.851 271.471 762.686 272.771C716.522 274.071 654.3 291.37 642.762 295.875C631.225 300.38 626.084 301.651 628.654 304.842C631.225 308.034 645.318 319.6 643.398 324.726C641.477 329.852 610.084 358.111 608.121 362.545C606.157 366.978 614.546 382.428 615.817 386.284C617.088 390.139 624.163 391.41 629.925 392.06C635.687 392.71 1046.14 392.06 1046.14 392.06C1046.14 392.06 1051.92 385.013 1050.63 376.032C1049.35 367.05 1047.98 355.209 1042.19 347.628C1037.51 341.477 1032.67 334.993 1020.53 333.708C1012.83 332.885 944.818 314.459 942.883 313.81Z"
          fill="white"
        />
        <mask
          id="mask7_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="607"
          y="272"
          width="444"
          height="121"
        >
          <path
            d="M942.885 313.808C942.885 313.808 932.633 307.396 885.818 292.003C839.004 276.61 808.853 271.47 762.688 272.769C716.524 274.069 654.302 291.368 642.764 295.873C631.227 300.378 626.086 301.649 628.656 304.84C631.227 308.032 645.32 319.598 643.4 324.724C641.479 329.85 610.086 358.11 608.123 362.543C606.159 366.976 614.548 382.426 615.819 386.282C617.09 390.137 624.165 391.408 629.927 392.058C635.689 392.708 1046.15 392.058 1046.15 392.058C1046.15 392.058 1051.92 385.011 1050.64 376.03C1049.35 367.048 1047.98 355.207 1042.19 347.626C1037.51 341.475 1032.67 334.991 1020.53 333.706C1012.83 332.883 944.82 314.457 942.885 313.808Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_15038_34725)">
          <path
            d="M942.885 313.808C942.885 313.808 932.633 307.396 885.818 292.003C839.004 276.61 808.853 271.47 762.688 272.769C716.524 274.069 654.302 291.368 642.764 295.873C631.227 300.378 626.086 301.649 628.656 304.84C631.227 308.032 645.32 319.598 643.4 324.724C641.479 329.85 610.086 358.11 608.123 362.543C606.159 366.976 614.548 382.426 615.819 386.282C617.09 390.137 624.165 391.408 629.927 392.058C635.689 392.708 1046.15 392.058 1046.15 392.058C1046.15 392.058 1051.92 385.011 1050.64 376.03C1049.35 367.048 1047.98 355.207 1042.19 347.626C1037.51 341.475 1032.67 334.991 1020.53 333.706C1012.83 332.883 944.82 314.457 942.885 313.808Z"
            fill="white"
          />
          <mask
            id="mask8_15038_34725"
            //style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="607"
            y="272"
            width="444"
            height="121"
          >
            <path
              d="M942.885 313.806C942.885 313.806 932.633 307.394 885.818 292.001C839.004 276.608 808.853 271.468 762.688 272.767C716.524 274.067 654.302 291.366 642.764 295.871C631.227 300.376 626.086 301.647 628.656 304.838C631.227 308.03 645.32 319.596 643.4 324.722C641.479 329.848 610.086 358.108 608.123 362.541C606.159 366.974 614.548 382.425 615.819 386.28C617.09 390.136 624.165 391.406 629.927 392.056C635.689 392.706 1046.15 392.056 1046.15 392.056C1046.15 392.056 1051.92 385.009 1050.64 376.028C1049.35 367.046 1047.98 355.205 1042.19 347.624C1037.51 341.473 1032.67 334.989 1020.53 333.704C1012.83 332.881 944.82 314.455 942.885 313.806Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask8_15038_34725)">
            <path
              opacity="0.15"
              d="M942.885 313.806C942.885 313.806 932.633 307.394 885.818 292.001C839.004 276.608 808.853 271.468 762.688 272.767C716.524 274.067 654.302 291.366 642.764 295.871C631.227 300.376 626.086 301.647 628.656 304.838C631.227 308.03 645.32 319.596 643.4 324.722C641.479 329.848 610.086 358.108 608.123 362.541C606.159 366.974 614.548 382.425 615.819 386.28C617.09 390.136 624.165 391.406 629.927 392.056C635.689 392.706 1046.15 392.056 1046.15 392.056C1046.15 392.056 1051.92 385.009 1050.64 376.028C1049.35 367.046 1047.98 355.205 1042.19 347.624C1037.51 341.473 1032.67 334.989 1020.53 333.704C1012.83 332.881 944.82 314.455 942.885 313.806Z"
              fill="black"
            />
          </g>
        </g>
        <path
          d="M942.883 313.81C942.883 313.81 932.631 307.398 885.816 292.005C839.002 276.612 808.851 271.471 762.686 272.771C716.522 274.071 654.3 291.37 642.762 295.875C631.225 300.38 626.084 301.651 628.654 304.842C631.225 308.034 645.318 319.6 643.398 324.726C641.477 329.852 610.084 358.111 608.121 362.545C606.157 366.978 614.546 382.428 615.817 386.284C617.088 390.139 624.163 391.41 629.925 392.06C635.687 392.71 1046.14 392.06 1046.14 392.06C1046.14 392.06 1051.92 385.013 1050.63 376.032C1049.35 367.05 1047.98 355.209 1042.19 347.628C1037.51 341.477 1032.67 334.993 1020.53 333.708C1012.83 332.885 944.818 314.459 942.883 313.81Z"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M597.236 303.529V346.849C597.236 346.849 623.604 325.189 620.34 319.023C617.077 312.858 597.236 303.529 597.236 303.529Z"
          fill="#7879F1"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M910.828 313.81C910.828 313.81 900.561 307.398 853.746 292.005C806.932 276.612 776.796 271.471 730.617 272.771C684.438 274.071 622.23 291.37 610.765 295.875C599.299 300.38 594.086 301.651 596.657 304.842C599.227 308.034 613.335 319.6 611.4 324.726C609.465 329.852 577.986 358.111 576.065 362.545C574.145 366.978 582.477 382.428 583.762 386.284C585.047 390.139 592.094 391.41 597.87 392.06C603.646 392.71 1014.07 392.06 1014.07 392.06C1014.07 392.06 1019.85 385.013 1018.56 376.032C1017.28 367.05 1015.85 355.223 1010.07 347.614C1005.4 341.462 1000.54 334.979 988.414 333.693C980.775 332.885 912.748 314.459 910.828 313.81Z"
          fill="white"
        />
        <mask
          id="mask9_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="575"
          y="272"
          width="444"
          height="121"
        >
          <path
            d="M910.826 313.808C910.826 313.808 900.559 307.396 853.745 292.003C806.93 276.61 776.794 271.47 730.615 272.769C684.436 274.069 622.228 291.368 610.763 295.873C599.297 300.378 594.085 301.649 596.655 304.84C599.225 308.032 613.333 319.598 611.398 324.724C609.463 329.85 577.984 358.11 576.063 362.543C574.143 366.976 582.475 382.426 583.76 386.282C585.045 390.137 592.092 391.408 597.868 392.058C603.644 392.708 1014.07 392.058 1014.07 392.058C1014.07 392.058 1019.85 385.011 1018.56 376.03C1017.28 367.048 1015.85 355.222 1010.07 347.612C1005.39 341.46 1000.54 334.977 988.412 333.691C980.773 332.883 912.746 314.457 910.826 313.808Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask9_15038_34725)">
          <path
            opacity="0.15"
            d="M1010.07 347.612C1005.4 341.46 1000.54 334.977 988.415 333.692C986.393 333.475 980.141 332.031 972.011 330.01C933.254 329.62 911.666 345.287 853.762 350.601C778.356 357.503 726.184 338.659 663.341 331.107C637.133 327.973 615.978 331.829 600.195 337.374C590.304 347.366 577.265 359.713 576.052 362.543C574.131 367.034 582.463 382.427 583.748 386.282C585.033 390.138 592.08 391.408 597.856 392.058C603.632 392.708 1014.06 392.058 1014.06 392.058C1014.06 392.058 1019.84 385.012 1018.55 376.03C1017.27 367.048 1015.85 355.222 1010.07 347.612Z"
            fill="black"
          />
        </g>
        <path
          d="M910.828 313.81C910.828 313.81 900.561 307.398 853.746 292.005C806.932 276.612 776.796 271.471 730.617 272.771C684.438 274.071 622.23 291.37 610.765 295.875C599.299 300.38 594.086 301.651 596.657 304.842C599.227 308.034 613.335 319.6 611.4 324.726C609.465 329.852 577.986 358.111 576.065 362.545C574.145 366.978 582.477 382.428 583.762 386.284C585.047 390.139 592.094 391.41 597.87 392.06C603.646 392.71 1014.07 392.06 1014.07 392.06C1014.07 392.06 1019.85 385.013 1018.56 376.032C1017.28 367.05 1015.85 355.223 1010.07 347.614C1005.4 341.462 1000.54 334.979 988.414 333.693C980.775 332.885 912.748 314.459 910.828 313.81Z"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.6"
          d="M668.295 290.156L693.955 317.26H882.57V311.108C882.57 311.108 843.914 295.932 803.15 288.004C762.386 280.077 752.206 280.784 733.838 281.131C715.47 281.477 668.295 290.156 668.295 290.156Z"
          fill="#8F8F8F"
        />
        <mask
          id="mask10_15038_34725"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="668"
          y="280"
          width="215"
          height="38"
        >
          <path
            d="M668.291 290.154L693.951 317.258H882.566V311.106C882.566 311.106 843.91 295.93 803.146 288.002C762.382 280.075 752.202 280.782 733.834 281.129C715.466 281.476 668.291 290.154 668.291 290.154Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask10_15038_34725)">
          <path
            d="M760.263 305.405C736.254 299.923 711.137 301.996 688.352 311.34L693.954 317.261H882.57V311.109C882.57 311.109 876.317 308.654 866.18 305.203C844.968 313.347 802.89 314.791 760.263 305.405Z"
            fill="black"
            stroke="black"
            strokeWidth="1.444"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M668.295 290.156L693.955 317.26H882.57V311.108C882.57 311.108 843.914 295.932 803.15 288.004C762.386 280.077 752.206 280.784 733.838 281.131C715.47 281.477 668.295 290.156 668.295 290.156Z"
          stroke="black"
          strokeWidth="5.776"
          strokeMiterlimit="10"
        />
        <path
          d="M663.402 424.703C684.191 419.876 697.131 399.112 692.304 378.324C687.478 357.536 666.714 344.596 645.926 349.422C625.137 354.248 612.197 375.012 617.024 395.801C621.85 416.589 642.614 429.529 663.402 424.703Z"
          fill="black"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M654.706 359.152C649.189 359.152 643.796 360.789 639.21 363.855C634.624 366.92 631.05 371.278 628.941 376.375C626.831 381.473 626.282 387.082 627.361 392.492C628.441 397.902 631.101 402.87 635.005 406.768C638.908 410.666 643.881 413.318 649.293 414.389C654.704 415.46 660.312 414.902 665.407 412.785C670.501 410.668 674.853 407.087 677.911 402.496C680.97 397.905 682.598 392.509 682.59 386.993C682.574 379.606 679.63 372.527 674.403 367.308C669.176 362.089 662.092 359.156 654.706 359.152ZM673.478 381.448L657.233 384.495L661.839 368.827C664.602 369.917 667.082 371.618 669.093 373.804C671.104 375.99 672.593 378.604 673.449 381.448H673.478ZM656.15 385.635V388.437H653.363V385.635H656.15ZM649.175 368.307L652.222 384.552L636.54 379.931C637.616 377.173 639.302 374.694 641.473 372.681C643.644 370.667 646.243 369.172 649.074 368.307H649.175ZM636.021 392.624L652.266 389.577L647.63 405.245C644.854 404.169 642.361 402.472 640.341 400.285C638.321 398.098 636.829 395.477 635.977 392.624H636.021ZM660.337 405.765L657.291 389.534L672.929 394.213C671.835 396.969 670.129 399.44 667.94 401.442C665.751 403.443 663.137 404.921 660.294 405.765H660.337Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M663.353 386.99C663.353 385.277 662.845 383.601 661.893 382.177C660.941 380.752 659.588 379.641 658.005 378.986C656.422 378.33 654.68 378.158 652.999 378.493C651.318 378.827 649.775 379.652 648.563 380.864C647.351 382.075 646.526 383.619 646.192 385.3C645.858 386.981 646.029 388.723 646.685 390.306C647.341 391.889 648.451 393.242 649.876 394.194C651.301 395.146 652.976 395.654 654.689 395.654C656.987 395.654 659.191 394.741 660.816 393.117C662.441 391.492 663.353 389.288 663.353 386.99Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M76.3301 425.705H666.753"
          stroke="black"
          strokeWidth="1.444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M209.988 321.012L305.486 321.011L401.986 321.011L286.985 392.01L209.988 321.012Z"
          fill="black"
        />
        <rect
          x="255.668"
          y="345.227"
          width="1.93631"
          height="8.39067"
          fill="#7879F1"
        />
        <circle
          cx="256.636"
          cy="339.659"
          r="4.71975"
          stroke="#7879F1"
          strokeWidth="1.85563"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M268.209 334.031C269.208 334.031 270.206 334.031 271.205 334.031C271.205 339.673 271.205 345.315 271.205 350.958C274.932 350.958 278.66 350.958 282.388 350.958C282.388 351.802 282.388 352.646 282.388 353.49C277.662 353.49 272.935 353.49 268.209 353.49C268.209 347.004 268.209 340.517 268.209 334.031Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M308.616 353.623C308.594 353.623 308.572 353.623 308.55 353.623C307.529 353.608 306.553 353.375 305.621 352.924C304.418 352.161 303.763 351.062 303.657 349.625C303.623 346.738 303.612 343.85 303.623 340.962C302.736 340.962 301.848 340.962 300.961 340.962C300.961 340.229 300.961 339.496 300.961 338.763C301.848 338.763 302.736 338.763 303.623 338.763C303.623 337.674 303.623 336.586 303.623 335.498C304.578 335.498 305.532 335.498 306.486 335.498C306.486 336.586 306.486 337.674 306.486 338.763C307.995 338.763 309.504 338.763 311.013 338.763C311.013 339.496 311.013 340.229 311.013 340.962C309.504 340.962 307.995 340.962 306.486 340.962C306.475 343.583 306.486 346.204 306.519 348.825C306.503 350.231 307.18 351.041 308.55 351.258C309.504 351.347 310.37 351.113 311.146 350.558C311.456 351.202 311.767 351.846 312.078 352.491C311.649 352.906 311.138 353.183 310.547 353.324C309.904 353.492 309.26 353.592 308.616 353.623Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M292.241 353.624C292.219 353.624 292.196 353.624 292.174 353.624C289.778 353.643 287.67 352.877 285.85 351.325C284.044 349.391 283.389 347.115 283.886 344.495C284.684 341.275 286.715 339.353 289.978 338.73C292.502 338.29 294.788 338.824 296.834 340.33C298.555 341.795 299.353 343.639 299.231 345.861C295.132 346.578 291.049 347.344 286.982 348.16C287.747 349.766 289.011 350.732 290.776 351.059C292.633 351.423 294.364 351.112 295.969 350.126C296.245 349.905 296.511 349.671 296.768 349.426C297.323 349.982 297.855 350.559 298.365 351.158C297.436 352.213 296.282 352.913 294.904 353.258C294.018 353.465 293.13 353.587 292.241 353.624ZM291.109 340.896C292.987 340.765 294.529 341.409 295.736 342.829C296.079 343.314 296.311 343.847 296.435 344.428C293.132 345.023 289.825 345.6 286.516 346.161C286.612 343.134 288.143 341.379 291.109 340.896Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M326.989 351.223C326.989 351.956 326.989 352.689 326.989 353.423C322.64 353.423 318.29 353.423 313.941 353.423C313.911 352.771 313.945 352.127 314.041 351.49C317.048 347.991 320.054 344.493 323.061 340.994C320.066 340.961 317.07 340.95 314.074 340.961C314.074 340.228 314.074 339.495 314.074 338.762C318.335 338.762 322.595 338.762 326.855 338.762C326.867 339.362 326.855 339.962 326.822 340.561C323.771 344.104 320.72 347.647 317.669 351.19C320.775 351.223 323.882 351.235 326.989 351.223Z"
          fill="white"
        />
        <path
          d="M172.738 92.7152C167.951 90.8914 162.89 89.9763 157.795 90.0128C141.318 90.0128 129.68 98.6906 129.598 111.148C129.495 120.299 137.868 125.456 144.201 128.514C150.699 131.658 152.872 133.639 152.852 136.449C152.811 140.723 147.661 142.661 142.873 142.661C136.292 142.661 132.698 141.703 127.167 139.302L125.118 138.301L122.78 152.642C126.786 154.386 134.006 155.883 141.462 156.012C158.979 156.012 170.431 147.388 170.565 134.134C170.709 126.824 166.178 121.3 156.642 116.735C150.844 113.775 147.249 111.793 147.249 108.79C147.249 106.13 150.329 103.288 156.765 103.288C161.049 103.18 165.306 104.02 169.257 105.753L170.802 106.475L173.14 92.6183L172.738 92.7152ZM215.497 91.1756H202.624C198.618 91.1756 195.601 92.3276 193.85 96.4835L169.092 155.118H186.61L190.112 145.504L211.491 145.525C211.996 147.775 213.541 155.118 213.541 155.118H228.988L215.497 91.1756ZM105.901 90.6372H122.595L112.152 154.612H95.4791L105.901 90.6157V90.6372ZM63.523 125.898L65.2531 134.78L81.5659 91.1756H99.2483L72.977 155.032H55.3357L40.9179 100.962C40.6844 100.071 40.136 99.3051 39.3834 98.8198C34.1868 96.0134 28.6807 93.8836 22.9883 92.4783L23.2148 91.1325H50.0835C53.7292 91.2832 56.6642 92.4783 57.6426 96.5481L63.5127 125.93L63.523 125.898ZM194.9 132.422L201.553 114.528C201.471 114.722 202.923 110.835 203.767 108.434L204.91 113.958L208.772 132.411H194.89L194.9 132.422Z"
          fill="black"
        />
      </g>
      <path
        d="M323.025 414.286C320.73 415.762 318.315 417.043 315.805 418.113C312.715 419.297 310.664 420.019 311.256 421.94C311.848 423.86 315.213 425.463 319.487 426.344C323.761 427.225 331.386 425.319 333.45 424.726C335.515 424.134 335.804 420.755 334.331 418.849C332.858 416.943 325.523 413.261 323.025 414.286Z"
        fill="#090814"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M324.351 425.321C320.813 425.321 314.936 423.993 314.936 422.679C314.936 421.365 316.38 420.325 314.647 419.877C312.915 419.43 311.76 421.841 311.471 422.404C312.424 424.108 315.586 425.538 319.499 426.346C323.831 427.227 331.398 425.321 333.463 424.729C333.833 424.603 334.168 424.389 334.437 424.104C334.706 423.82 334.902 423.474 335.008 423.097C331.59 424.379 327.996 425.129 324.351 425.321Z"
        fill="white"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M341.103 319.908L341.984 322.796C341.436 323.238 340.993 323.796 340.688 324.431C340.383 325.065 340.224 325.76 340.223 326.464C340.549 328.086 341.433 329.542 342.721 330.579"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M332.123 314.172C331.23 316.571 330.497 319.026 329.928 321.522V325.493C329.928 326.229 327.04 341.81 326.391 351.369C325.741 360.929 325.365 372.091 324.629 374.603C323.892 377.116 324.773 378.415 324.918 381.953C325.062 385.491 325.221 403.267 325.221 403.267C325.221 403.267 324.484 407.974 324.34 409.144C324.196 410.313 322.723 410.761 322.275 411.497C321.793 412.689 321.402 413.915 321.105 415.165C323.392 415.786 325.471 417.008 327.127 418.703C329.192 421.201 334.766 424.291 335.502 424.132C336.239 423.974 336.686 421.938 337.856 419.136C339.026 416.335 338.896 414.876 339.473 413.707C340.051 412.537 340.917 410.617 342.275 401.794C343.632 392.971 344.773 379.888 345.509 378.127C346.246 376.365 350.058 369.751 350.058 369.751C353.38 363.349 356.232 356.713 358.592 349.896C360.747 343.574 362.37 337.082 363.444 330.489C364.13 325.958 364.569 321.393 364.758 316.814V315.37C364.758 315.37 359.328 317.421 349.624 316.396C339.921 315.37 332.123 314.172 332.123 314.172Z"
        fill="white"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M352.422 322.711C352.422 322.711 345.808 326.234 344.783 327.996C343.758 329.758 342.429 337.266 339.484 346.522C336.538 355.779 333.895 362.84 331.397 370.637C329.814 375.102 328.781 379.742 328.322 384.456C327.426 390.232 325.434 405.481 325.434 405.481"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M335.012 421.493C335.012 421.493 338.246 420.323 338.246 413.71C338.246 407.096 337.062 402.389 338.246 396.064C339.43 389.739 342.362 379.444 344.123 376.368C345.885 373.292 347.95 369.307 347.95 369.307"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M346.259 367.101C346.259 367.101 351.399 370.639 352.424 369.31C354.009 366.494 355.338 363.541 356.395 360.487C357.724 357.108 358.302 355.491 358.302 355.491C358.302 355.491 359.746 355.347 359.919 354.047C360.092 352.747 360.366 350.22 360.366 350.22C358.783 350.173 357.207 349.979 355.659 349.643C352.872 349.051 351.832 349.195 351.255 350.524C350.791 351.422 350.395 352.353 350.071 353.311C349.19 355.664 345.739 363.606 345.667 364.776C345.594 365.945 345.378 366.22 346.259 367.101Z"
        fill="white"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M358.302 355.486C356.281 355.37 354.27 355.124 352.281 354.75C350.663 354.317 351.544 352.252 351.544 352.252"
        fill="#7879F1"
      />
      <path
        d="M358.302 355.486C356.281 355.37 354.27 355.124 352.281 354.75C350.663 354.317 351.544 352.252 351.544 352.252"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M356.254 349.609C356.254 349.609 360.081 337.263 360.586 334.75L361.178 332.252"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M357.421 319.314C357.421 319.314 356.395 324.47 357.421 327.256C358.6 329.672 360.374 331.749 362.576 333.292"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M336.109 321.525C336.109 321.525 343.763 323.735 348.311 322.117"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M350.072 263.75C350.072 263.75 340.079 266.248 337.076 266.84C334.072 267.432 332.527 267.432 332.08 268.746C328.87 274.752 325.129 280.459 320.903 285.799C317.524 289.482 317.235 293.019 318.261 294.911C319.286 296.803 320.47 297.712 324.874 296.528C327.712 295.669 330.227 293.979 332.094 291.677C332.094 291.677 333.134 302.709 331.661 307.113C330.188 311.517 329.596 315.055 330.621 315.777C335.083 318.234 340.084 319.549 345.177 319.603C353.263 319.892 362.505 319.156 363.949 317.683C364.448 317.281 364.84 316.763 365.092 316.174C365.344 315.584 365.447 314.942 365.393 314.304C365.393 313.279 364.815 306.376 366.577 300.933C368.339 295.489 373.335 281.525 373.479 276.226C373.624 270.926 371.573 270.204 367.602 268.587C363.631 266.97 357.306 264.183 355.848 263.88C353.928 263.71 351.998 263.667 350.072 263.75Z"
        fill="#090814"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M348.007 261.106C346.801 261.88 345.67 262.765 344.628 263.748C344.469 264.196 339.328 272.412 339.92 272.412C340.513 272.412 346.982 269.178 346.982 269.178C346.982 269.178 350.202 264.326 349.87 262.405C349.857 262.187 349.795 261.974 349.689 261.783C349.582 261.592 349.434 261.428 349.254 261.303C349.075 261.178 348.869 261.095 348.653 261.061C348.437 261.027 348.216 261.042 348.007 261.106Z"
        fill="white"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M346.765 259.473C346.919 262.726 346.672 265.985 346.029 269.177C345.004 273.148 344.267 275.79 345.74 276.238C347.213 276.685 352.065 269.465 353.379 267.415C354.155 266.235 354.748 264.945 355.141 263.588L356.18 252.123C356.18 252.123 350.289 258.477 346.765 259.473Z"
        fill="#FFB8B8"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M341.481 232.128C341.481 232.128 340.037 244.633 340.037 249.34C340.037 254.048 343.127 260.069 344.586 260.647C346.044 261.225 349.741 261.831 351.214 260.806C352.687 259.781 356.788 253.456 356.788 253.456C358.178 249.036 359.212 244.511 359.878 239.926C360.325 234.929 358.997 232.576 354.434 231.103C349.871 229.63 343.82 229.601 341.481 232.128Z"
        fill="#FFB8B8"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M344.857 252.861C344.857 252.861 348.828 253.742 350.879 251.836"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-136-inside-1_15038_34725" fill="white">
        <path d="M350.431 243.002C350.431 243.608 350.07 244.07 349.738 244.041C349.406 244.012 349.204 243.478 349.262 242.886C349.32 242.294 349.637 241.803 349.969 241.846C350.301 241.89 350.504 242.395 350.431 243.002Z" />
      </mask>
      <path
        d="M350.431 243.002C350.431 243.608 350.07 244.07 349.738 244.041C349.406 244.012 349.204 243.478 349.262 242.886C349.32 242.294 349.637 241.803 349.969 241.846C350.301 241.89 350.504 242.395 350.431 243.002Z"
        fill="#090814"
      />
      <path
        d="M350.431 243.002L348.998 242.831L348.987 242.916V243.002H350.431ZM348.987 243.002C348.987 243.029 348.977 243.002 349.027 242.93C349.053 242.893 349.117 242.815 349.239 242.741C349.365 242.665 349.582 242.578 349.863 242.603L349.613 245.48C350.509 245.558 351.114 244.984 351.391 244.589C351.705 244.142 351.875 243.58 351.875 243.002H348.987ZM349.863 242.603C350.175 242.63 350.399 242.776 350.526 242.9C350.642 243.013 350.684 243.113 350.694 243.137C350.712 243.181 350.688 243.14 350.699 243.026L347.825 242.746C347.778 243.225 347.826 243.746 348.014 244.213C348.174 244.611 348.632 245.394 349.613 245.48L349.863 242.603ZM350.699 243.026C350.709 242.926 350.739 242.893 350.712 242.932C350.699 242.952 350.637 243.04 350.501 243.126C350.354 243.22 350.1 243.32 349.783 243.278L350.156 240.415C349.167 240.286 348.549 240.971 348.313 241.324C348.03 241.746 347.873 242.254 347.825 242.746L350.699 243.026ZM349.783 243.278C349.509 243.243 349.306 243.113 349.183 242.994C349.07 242.883 349.025 242.783 349.011 242.749C348.986 242.686 349.012 242.712 348.998 242.831L351.865 243.172C351.923 242.684 351.884 242.155 351.693 241.677C351.518 241.24 351.066 240.533 350.156 240.415L349.783 243.278Z"
        fill="#090814"
        mask="url(#path-136-inside-1_15038_34725)"
      />
      <mask id="path-138-inside-2_15038_34725" fill="white">
        <path d="M344.269 242.408C344.269 243.015 343.893 243.477 343.576 243.448C343.258 243.419 343.041 242.899 343.099 242.293C343.157 241.686 343.475 241.224 343.792 241.253C344.11 241.282 344.327 241.802 344.269 242.408Z" />
      </mask>
      <path
        d="M344.269 242.408C344.269 243.015 343.893 243.477 343.576 243.448C343.258 243.419 343.041 242.899 343.099 242.293C343.157 241.686 343.475 241.224 343.792 241.253C344.11 241.282 344.327 241.802 344.269 242.408Z"
        fill="#090814"
      />
      <path
        d="M344.269 242.408L342.831 242.272L342.825 242.34V242.408H344.269ZM342.825 242.408C342.825 242.423 342.818 242.395 342.865 242.329C342.888 242.296 342.948 242.222 343.066 242.15C343.183 242.079 343.407 241.983 343.706 242.01L343.445 244.886C344.347 244.968 344.952 244.377 345.218 244.004C345.532 243.562 345.713 243 345.713 242.408H342.825ZM343.706 242.01C344.022 242.039 344.245 242.188 344.365 242.305C344.478 242.415 344.521 242.511 344.531 242.535C344.55 242.582 344.526 242.543 344.537 242.43L341.662 242.156C341.615 242.649 341.67 243.174 341.862 243.639C342.024 244.03 342.481 244.799 343.445 244.886L343.706 242.01ZM344.537 242.43C344.547 242.323 344.576 242.296 344.546 242.341C344.53 242.365 344.471 242.448 344.342 242.531C344.206 242.619 343.968 242.719 343.662 242.691L343.923 239.815C342.982 239.73 342.391 240.371 342.15 240.729C341.867 241.149 341.709 241.656 341.662 242.156L344.537 242.43ZM343.662 242.691C343.346 242.663 343.123 242.514 343.003 242.396C342.89 242.287 342.847 242.191 342.837 242.166C342.818 242.12 342.842 242.158 342.831 242.272L345.706 242.545C345.753 242.052 345.698 241.528 345.506 241.062C345.344 240.672 344.887 239.903 343.923 239.815L343.662 242.691Z"
        fill="#090814"
        mask="url(#path-138-inside-2_15038_34725)"
      />
      <path
        d="M341.479 240.216C341.682 239.946 341.942 239.726 342.241 239.569C342.539 239.413 342.869 239.324 343.206 239.311C343.543 239.297 343.879 239.358 344.189 239.489C344.5 239.621 344.777 239.82 345.002 240.071"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M347.789 239.925C347.789 239.925 350.59 237.716 352.121 240.069"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M339.125 230.076C339.115 230.196 339.115 230.317 339.125 230.437C339.354 231.187 339.766 231.868 340.323 232.419C340.88 232.971 341.564 233.377 342.316 233.599C345.55 234.928 353.189 233.311 355.832 234.191C358.474 235.072 357.276 237.57 355.687 238.451C354.099 239.332 353.781 239.78 354.518 240.805C355.254 241.83 357.016 242.87 356.424 244.04C355.832 245.209 355.543 246.393 356.568 246.538C357.593 246.682 358.33 246.682 358.778 246.104C359.225 245.527 362.156 239.188 362.156 235.52C362.156 231.852 360.395 230.22 356.279 228.603C352.164 226.986 343.63 225.961 341.132 227.434C340.607 227.677 340.15 228.046 339.801 228.506C339.451 228.967 339.219 229.506 339.125 230.076Z"
        fill="#090814"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M356.182 251.795C356.556 252.166 357.045 252.4 357.568 252.461C358.092 252.521 358.621 252.404 359.07 252.128C360.687 251.102 361.423 247.42 360.398 245.514C359.373 243.608 357.308 247.276 357.308 247.276"
        fill="#FFB8B8"
      />
      <path
        d="M356.182 251.795C356.556 252.166 357.045 252.4 357.568 252.461C358.092 252.521 358.621 252.404 359.07 252.128C360.687 251.102 361.423 247.42 360.398 245.514C359.373 243.608 357.308 247.276 357.308 247.276"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M350.071 263.75C350.071 263.75 349.782 276.832 350.663 275.302C351.544 273.771 358.894 264.139 358.894 264.139L357.132 258.984L350.071 263.75Z"
        fill="white"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M363.008 278.162L358.012 293.743L367.123 305.295C367.123 305.295 367.571 306.175 368.307 305.15C369.044 304.125 369.622 304.125 370.806 298.537C371.99 292.948 373.694 279.418 373.592 276.183C373.491 272.949 372.856 271.332 370.358 270.898"
        fill="#090814"
      />
      <path
        d="M363.008 278.162L358.012 293.743L367.123 305.295C367.123 305.295 367.571 306.175 368.307 305.15C369.044 304.125 369.622 304.125 370.806 298.537C371.99 292.948 373.694 279.418 373.592 276.183C373.491 272.949 372.856 271.332 370.358 270.898"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M335.084 296.24C335.084 296.24 357.87 304.904 360.96 305.063C364.051 305.222 364.773 303.157 363.459 299.287C362.145 295.417 358.159 293.251 354.795 292.226C350.923 291.321 346.889 291.371 343.04 292.37C339.633 293.294 335.084 295.07 335.084 296.24Z"
        fill="#FFB8B8"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M354.043 292.124C354.043 292.124 359.487 303.878 359.819 304.918C360.151 305.957 365.71 306.679 367.472 306.535C369.234 306.391 368.497 303.734 366.736 299.921C364.974 296.109 362.173 292.701 360.122 292.124C358.109 291.802 356.057 291.802 354.043 292.124Z"
        fill="white"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M323.618 288.008C321.713 288.16 319.835 288.553 318.029 289.177C315.964 290.058 315.531 296.239 316.585 298.448C317.639 300.657 320.845 300.643 323.054 300.498C323.678 300.478 324.29 300.323 324.849 300.043C325.407 299.764 325.898 299.368 326.289 298.881L327.906 288.008H323.618Z"
        fill="#090814"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M327.879 288.011C327.879 288.011 344.485 288.011 348.6 287.867C352.716 287.723 353.755 288.748 354.636 290.221C355.517 291.694 355.806 292.271 355.806 292.271C350.595 292.773 345.465 293.908 340.528 295.65C333.756 298.307 327.879 300.646 325.814 300.646C323.749 300.646 322.926 295.361 323.763 291.679C323.934 290.693 324.434 289.794 325.182 289.128C325.929 288.462 326.879 288.068 327.879 288.011Z"
        fill="#FFB8B8"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M323.17 286.982C323.17 286.982 330.231 286.824 330.087 288.152C329.942 289.481 328.643 299.473 328.166 300.354C327.69 301.235 323.473 301.682 321.408 301.538C319.343 301.394 320.08 294.766 321.408 291.675C322.097 290.151 322.685 288.583 323.17 286.982Z"
        fill="white"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M344.19 320.572C344.19 320.572 345.374 322.334 344.927 322.782C344.479 323.229 342.284 325.28 342.429 326.594C342.497 327.358 342.806 328.08 343.31 328.659"
        stroke="#090814"
        strokeWidth="1.444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_15038_34725">
          <rect width="722" height="441.864" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VisaPromo;

import React from 'react'
import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';	

function DriverNew() {

  const { t } = useTranslation(['letzDriver', 'common']);

  return (<>
      <Container sx={{minHeight: 'calc(78vh - 100px)'}}>
        <Box sx={{mt: '22vh',mb: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography  className='title_new_driver'
                       sx={{fontFamily: 'Montserrat', 
                            fontWeight: 800,
                            textAlign: 'center',
                            width: '95%'                            
                            }}> {t('instruction')}
          </Typography>
          <iframe className='youtube_player'
                  src={t('videoLanguage')}
                  title="YouTube video player" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen={true}>               
          </iframe>            
        </Box>     
      </Container>
  </>)
}

export default DriverNew
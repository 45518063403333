const QR_Code = () => {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="130.000000pt" height="130.000000pt" viewBox="0 0 200.000000 200.000000"
     preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.11, written by Peter Selinger 2001-2013
    </metadata><g><rect x="0" y="0" width="130" height="130" fill="#ffffff" />
    <g transform="translate(0.000000,200.000000) scale(0.033333,-0.033333)"
    fill="#000000" stroke="none">
    <path d="M357 5990 c-190 -23 -278 -81 -317 -206 -36 -116 -42 -236 -38 -696
    4 -407 6 -438 27 -513 38 -140 86 -188 226 -227 78 -22 93 -22 585 -22 492 0
    507 0 585 22 140 39 188 87 227 227 22 78 22 93 22 585 0 492 0 507 -22 585
    -39 140 -87 188 -227 227 -76 20 -102 22 -540 24 -253 1 -491 -2 -528 -6z
    m797 -240 c210 -20 256 -66 276 -276 13 -140 13 -488 0 -628 -15 -156 -46
    -214 -132 -250 -115 -48 -801 -48 -916 0 -86 36 -117 94 -132 250 -13 140 -13
    488 0 628 20 205 68 257 260 275 125 12 519 13 644 1z"/>
    <path d="M575 5502 c-41 -14 -54 -24 -70 -57 -18 -37 -20 -60 -20 -285 0 -225
    2 -248 20 -285 32 -65 75 -74 335 -74 260 0 303 9 335 74 18 37 20 60 20 285
    0 225 -2 248 -20 285 -32 65 -75 74 -335 74 -176 0 -224 -3 -265 -17z"/>
    <path d="M2416 5984 c-12 -12 -16 -36 -16 -100 0 -114 -10 -124 -124 -124
    -111 0 -116 -5 -116 -116 0 -114 -10 -124 -124 -124 -111 0 -116 -5 -116 -118
    0 -48 5 -94 10 -102 7 -11 35 -17 100 -20 66 -4 95 -10 107 -23 14 -14 18 -47
    23 -214 4 -109 10 -206 14 -216 10 -20 28 -25 119 -30 102 -5 101 -4 105 -210
    4 -271 3 -267 123 -267 109 0 119 -11 119 -124 0 -111 5 -116 116 -116 114 0
    124 -10 124 -120 0 -110 -10 -120 -124 -120 -111 0 -116 -5 -116 -116 0 -114
    -10 -124 -120 -124 -110 0 -120 10 -120 124 0 109 -6 116 -105 116 -130 0
    -133 8 -134 390 -1 243 -9 303 -41 320 -22 12 -149 13 -167 2 -24 -15 -33 -66
    -33 -190 -1 -204 -14 -237 -98 -263 -23 -7 -184 -13 -419 -17 -461 -8 -435 -1
    -443 -131 -6 -104 -14 -111 -122 -111 -105 0 -118 13 -118 111 0 86 -9 105
    -58 119 -46 12 -512 13 -566 0 -22 -5 -51 -20 -65 -35 -25 -25 -26 -29 -29
    -181 -6 -248 -3 -254 123 -254 110 0 115 5 115 116 0 72 3 87 21 105 19 19 30
    21 100 17 116 -5 112 2 119 -235 4 -109 10 -206 14 -216 10 -20 28 -25 119
    -30 103 -5 102 -4 102 -210 0 -95 -3 -184 -8 -199 -15 -54 -43 -62 -246 -69
    -102 -3 -187 -7 -188 -8 -28 -21 -33 -39 -33 -112 0 -109 -11 -119 -124 -119
    -112 0 -116 -5 -116 -120 0 -115 4 -120 116 -120 114 0 124 -10 124 -120 0
    -110 -10 -120 -124 -120 -112 0 -116 -5 -116 -120 0 -116 4 -120 120 -120 115
    0 120 4 120 116 0 114 10 124 124 124 112 0 116 5 116 120 0 115 -4 120 -116
    120 -72 0 -87 3 -105 21 -19 19 -21 30 -17 100 5 116 1 114 211 114 95 0 184
    -3 199 -8 53 -14 61 -43 68 -228 5 -165 6 -170 31 -195 38 -37 81 -43 319 -45
    360 -1 370 -5 370 -134 0 -99 7 -105 120 -105 115 0 120 4 120 116 0 113 10
    124 118 124 42 0 82 4 89 8 24 15 33 66 33 190 1 204 14 237 98 263 22 6 182
    14 392 18 484 10 462 -2 470 246 6 178 15 208 68 222 15 5 104 8 199 8 211 0
    205 4 213 -125 3 -65 9 -93 20 -100 8 -5 54 -10 102 -10 113 0 118 5 118 116
    0 72 3 87 21 105 19 19 30 21 100 17 115 -5 113 -1 117 -211 4 -271 3 -267
    123 -267 67 0 82 -3 100 -21 19 -19 21 -30 17 -100 -5 -114 1 -111 -223 -118
    -182 -6 -186 -7 -211 -32 -37 -38 -43 -81 -45 -319 -1 -358 -6 -370 -130 -370
    -98 0 -109 13 -109 124 0 111 -5 116 -116 116 -114 0 -124 10 -124 124 0 111
    -5 116 -116 116 -114 0 -124 10 -124 124 0 112 -5 116 -120 116 -116 0 -120
    -4 -120 -120 0 -115 4 -120 116 -120 114 0 124 -10 124 -120 0 -110 -10 -120
    -124 -120 -111 0 -116 -5 -116 -116 0 -114 -10 -124 -124 -124 -111 0 -116 -5
    -116 -116 0 -72 -3 -87 -21 -105 -19 -19 -30 -21 -100 -17 -116 5 -112 -2
    -119 235 -4 109 -10 206 -14 216 -11 23 -38 29 -115 25 -57 -3 -66 -6 -85 -31
    -21 -28 -21 -37 -21 -447 0 -410 0 -419 21 -447 20 -26 26 -28 101 -31 107 -4
    113 2 113 114 0 114 10 124 123 124 45 0 89 5 97 10 11 7 17 35 20 100 8 131
    -2 125 240 125 241 0 233 4 238 -114 4 -70 2 -81 -17 -100 -18 -18 -33 -21
    -100 -21 -119 0 -118 1 -124 -245 -2 -96 -8 -158 -16 -173 -25 -47 -67 -55
    -336 -61 -376 -9 -364 4 -365 -386 0 -249 7 -307 40 -325 10 -5 50 -10 89 -10
    109 0 109 0 113 267 4 210 2 206 117 211 70 4 81 2 100 -17 18 -18 21 -33 21
    -100 0 -120 -4 -119 267 -123 212 -4 205 0 213 -128 3 -65 9 -93 20 -100 8 -5
    54 -10 102 -10 114 0 118 4 118 120 0 115 -4 120 -116 120 -114 0 -124 10
    -124 124 0 111 -5 116 -116 116 -114 0 -124 10 -124 120 0 109 11 120 119 120
    73 0 91 5 112 33 1 1 5 86 8 188 9 261 3 254 241 254 240 0 235 5 235 -252 0
    -223 5 -215 -125 -223 -65 -3 -93 -9 -100 -20 -5 -8 -10 -54 -10 -102 0 -114
    4 -118 120 -118 115 0 120 4 120 116 0 72 3 87 21 105 19 19 30 21 100 17 115
    -5 113 -1 117 -211 4 -271 3 -267 123 -267 109 0 119 -11 119 -124 0 -111 5
    -116 118 -116 48 0 94 5 102 10 11 7 17 35 20 100 8 131 -2 125 240 125 242 0
    232 6 240 -125 3 -65 9 -93 20 -100 19 -13 181 -13 200 0 11 7 17 35 20 100 8
    128 1 124 213 128 272 4 267 2 267 127 0 110 -5 115 -116 115 -72 0 -87 3
    -105 21 -19 19 -21 30 -17 100 5 116 -2 112 235 119 109 4 206 10 216 14 20
    10 25 28 30 119 5 102 4 101 210 105 272 4 267 2 267 127 0 110 -5 115 -116
    115 -114 0 -124 10 -124 120 0 108 11 120 118 120 87 0 96 4 111 43 17 44 14
    340 -3 382 -21 48 -60 57 -237 53 -230 -5 -221 16 -230 -493 -7 -405 -11 -439
    -56 -456 -37 -14 -106 -11 -130 6 -41 29 -45 68 -52 595 -8 525 -11 559 -51
    580 -10 6 -48 10 -84 10 -94 0 -106 14 -106 124 0 111 -5 116 -116 116 -114 0
    -124 10 -124 123 0 45 -5 89 -10 97 -7 11 -35 17 -100 20 -66 4 -95 11 -107
    23 -14 14 -18 47 -23 204 -6 191 -12 223 -47 239 -10 4 -102 10 -206 14 -228
    7 -222 4 -222 120 0 115 -6 112 220 119 182 6 186 7 211 32 38 38 44 82 44
    329 0 247 -6 291 -44 329 -24 24 -31 25 -189 31 -97 4 -172 12 -186 19 -48 26
    -54 65 -60 366 -6 284 -11 315 -47 329 -8 3 -47 6 -85 6 -98 0 -109 13 -109
    124 0 111 -5 116 -116 116 -72 0 -87 3 -105 21 -19 19 -21 30 -17 100 5 115 1
    113 211 117 271 4 267 2 267 125 0 45 -5 89 -10 97 -7 11 -35 17 -100 20 -65
    4 -95 11 -107 23 -14 13 -17 48 -22 202 -6 182 -7 186 -32 211 -14 14 -43 29
    -65 34 -54 13 -520 12 -566 0 -49 -14 -58 -33 -58 -124 0 -100 7 -106 116
    -106 114 0 124 -10 124 -124 0 -111 5 -116 116 -116 114 0 124 -10 124 -120 0
    -110 -10 -120 -124 -120 -111 0 -116 -5 -116 -116 0 -114 -10 -124 -120 -124
    -110 0 -120 10 -120 124 0 111 -5 116 -116 116 -114 0 -124 10 -124 124 0 112
    -5 116 -120 116 -116 0 -120 -4 -120 -118 0 -48 5 -94 10 -102 7 -11 35 -17
    100 -20 130 -8 125 0 125 -223 0 -257 5 -252 -235 -252 -238 0 -232 -7 -241
    254 -3 102 -7 187 -8 188 -21 28 -39 33 -112 33 -108 0 -119 11 -119 120 0
    110 10 120 124 120 111 0 116 5 116 116 0 114 10 124 124 124 112 0 116 5 116
    120 0 116 -4 120 -120 120 -67 0 -92 -4 -104 -16z m1164 -1204 c17 -17 20 -33
    20 -102 0 -46 5 -90 10 -98 7 -11 35 -17 100 -20 131 -8 125 2 125 -240 0
    -242 6 -232 -125 -240 -65 -3 -93 -9 -100 -20 -5 -8 -10 -52 -10 -97 0 -123
    -4 -121 267 -125 210 -4 206 -2 211 -117 4 -70 2 -81 -17 -100 -18 -18 -33
    -21 -105 -21 -111 0 -116 -5 -116 -116 0 -114 -10 -124 -124 -124 -111 0 -116
    -5 -116 -116 0 -72 -3 -87 -21 -105 -19 -19 -30 -21 -100 -17 -115 5 -113 1
    -117 211 -4 271 -3 267 -123 267 -108 0 -119 11 -119 120 0 110 10 120 124
    120 111 0 116 5 116 116 0 113 10 124 118 124 42 0 82 4 89 8 25 16 33 69 33
    232 0 163 -8 216 -33 232 -7 4 -49 8 -94 8 -108 0 -113 -5 -113 -116 0 -114
    -10 -124 -124 -124 -111 0 -116 -5 -116 -116 0 -114 -10 -124 -120 -124 -110
    0 -120 10 -120 124 0 64 -4 88 -16 100 -12 12 -36 16 -100 16 -114 0 -124 10
    -124 120 0 67 3 83 20 100 17 17 33 20 100 20 110 0 120 -10 120 -124 0 -112
    5 -116 120 -116 115 0 120 4 120 116 0 114 10 124 124 124 111 0 116 5 116
    116 0 114 10 124 120 124 67 0 83 -3 100 -20z m-1488 -959 c53 -17 68 -43 68
    -123 0 -91 8 -98 116 -98 114 0 124 -10 124 -120 0 -110 -10 -120 -124 -120
    -112 0 -116 -5 -116 -120 0 -115 4 -120 116 -120 72 0 87 -3 105 -21 19 -19
    21 -30 17 -100 -5 -115 -1 -113 -211 -117 -263 -4 -267 -6 -267 -102 0 -81
    -11 -105 -57 -122 -55 -19 -791 -19 -846 0 -44 16 -57 41 -57 112 0 101 23
    108 390 109 243 1 303 9 320 41 5 10 10 52 10 94 0 100 -7 106 -116 106 -114
    0 -124 10 -124 120 0 110 10 120 124 120 109 0 116 6 116 106 0 42 -5 84 -10
    94 -18 33 -76 40 -350 40 -274 0 -332 -7 -350 -40 -5 -10 -10 -52 -10 -94 0
    -100 7 -106 116 -106 114 0 124 -10 124 -120 0 -109 -11 -120 -120 -120 -110
    0 -120 10 -120 124 0 111 -5 116 -116 116 -114 0 -124 10 -124 120 0 110 10
    120 124 120 107 0 116 7 116 96 0 107 18 123 150 135 134 12 938 3 982 -10z
    m768 -481 c17 -17 20 -33 20 -100 0 -67 -3 -83 -20 -100 -17 -17 -33 -20 -100
    -20 -67 0 -83 3 -100 20 -17 17 -20 33 -20 100 0 67 3 83 20 100 17 17 33 20
    100 20 67 0 83 -3 100 -20z m1440 -480 c17 -17 20 -33 20 -100 0 -67 -3 -83
    -20 -100 -17 -17 -33 -20 -100 -20 -67 0 -83 3 -100 20 -17 17 -20 33 -20 100
    0 67 3 83 20 100 17 17 33 20 100 20 67 0 83 -3 100 -20z m-2640 -480 c17 -17
    20 -33 20 -100 0 -67 -3 -83 -20 -100 -17 -17 -33 -20 -100 -20 -67 0 -83 3
    -100 20 -17 17 -20 33 -20 100 0 67 3 83 20 100 17 17 33 20 100 20 67 0 83
    -3 100 -20z m3117 -3 c14 -14 18 -35 18 -97 0 -119 7 -115 -235 -115 -237 0
    -233 -2 -238 103 -4 84 11 115 58 124 19 3 113 6 207 5 150 -2 175 -5 190 -20z
    m-62 -483 c27 -13 47 -32 60 -59 18 -37 20 -60 20 -275 0 -215 -2 -238 -20
    -275 -13 -27 -33 -47 -60 -60 -37 -18 -60 -20 -275 -20 -215 0 -238 2 -275 20
    -27 13 -47 33 -60 60 -18 37 -20 60 -20 275 0 363 -2 361 390 356 175 -2 205
    -4 240 -22z m-1375 -234 c17 -17 20 -33 20 -100 0 -67 -3 -83 -20 -100 -17
    -17 -33 -20 -100 -20 -67 0 -83 3 -100 20 -17 17 -20 33 -20 100 0 67 3 83 20
    100 17 17 33 20 100 20 67 0 83 -3 100 -20z m477 -243 c16 -16 18 -40 18 -217
    0 -242 4 -235 -115 -235 -115 0 -114 -2 -118 198 -4 195 4 246 38 265 36 19
    155 11 177 -11z m720 -480 c13 -12 18 -40 23 -106 3 -49 9 -93 13 -98 5 -4 49
    -10 98 -13 129 -8 124 2 124 -240 0 -241 4 -233 -114 -238 -70 -4 -81 -2 -100
    17 -18 18 -21 33 -21 105 0 111 -5 116 -116 116 -114 0 -124 10 -124 124 0
    112 -5 116 -120 116 -115 0 -120 -4 -120 -116 0 -114 -10 -124 -120 -124 -109
    0 -120 11 -120 120 0 110 10 120 124 120 109 0 116 7 116 103 0 90 14 120 60
    129 19 3 113 6 207 5 148 -2 175 -5 190 -20z"/>
    <path d="M1936 3584 c-12 -12 -16 -36 -16 -100 0 -114 -10 -124 -124 -124
    -112 0 -116 -5 -116 -120 0 -116 4 -120 120 -120 115 0 120 4 120 116 0 114
    10 124 124 124 112 0 116 5 116 120 0 116 -4 120 -120 120 -67 0 -92 -4 -104
    -16z"/>
    <path d="M4336 1664 c-12 -12 -16 -37 -16 -104 0 -116 4 -120 120 -120 116 0
    120 4 120 120 0 116 -4 120 -120 120 -67 0 -92 -4 -104 -16z"/>
    <path d="M4677 5990 c-190 -23 -278 -81 -317 -206 -36 -116 -42 -236 -38 -696
    4 -407 6 -438 27 -513 38 -140 86 -188 226 -227 78 -22 93 -22 585 -22 492 0
    507 0 585 22 140 39 188 87 227 227 22 78 22 93 22 585 0 492 0 507 -22 585
    -39 140 -87 188 -227 227 -76 20 -102 22 -540 24 -253 1 -491 -2 -528 -6z
    m797 -240 c210 -20 256 -66 276 -276 13 -140 13 -488 0 -628 -15 -156 -46
    -214 -132 -250 -115 -48 -801 -48 -916 0 -86 36 -117 94 -132 250 -13 140 -13
    488 0 628 20 205 68 257 260 275 125 12 519 13 644 1z"/>
    <path d="M4895 5502 c-41 -14 -54 -24 -70 -57 -18 -37 -20 -60 -20 -285 0
    -225 2 -248 20 -285 32 -65 75 -74 335 -74 260 0 303 9 335 74 18 37 20 60 20
    285 0 225 -2 248 -20 285 -32 65 -75 74 -335 74 -176 0 -224 -3 -265 -17z"/>
    <path d="M5296 4064 c-12 -12 -16 -37 -16 -104 0 -116 4 -120 120 -120 116 0
    120 4 120 120 0 67 -4 92 -16 104 -12 12 -37 16 -104 16 -67 0 -92 -4 -104
    -16z"/>
    <path d="M5780 3830 c-11 -7 -17 -35 -20 -100 -8 -128 -1 -124 -213 -128 -271
    -4 -267 -2 -267 -125 0 -45 5 -89 10 -97 7 -11 35 -17 100 -20 128 -8 124 -1
    128 -213 4 -271 3 -267 123 -267 67 0 82 -3 100 -21 19 -19 21 -30 17 -100 -5
    -116 -1 -114 -211 -114 -95 0 -184 3 -199 8 -54 15 -62 43 -68 241 -4 100 -10
    189 -14 199 -10 20 -28 25 -119 30 -102 5 -101 4 -105 210 -4 270 -3 267 -120
    267 -87 0 -96 -4 -111 -43 -15 -38 -14 -355 0 -396 12 -35 22 -38 122 -44 102
    -5 101 -4 105 -210 4 -271 3 -267 123 -267 109 0 119 -11 119 -124 0 -111 5
    -116 116 -116 114 0 124 -10 124 -124 0 -112 5 -116 120 -116 115 0 120 4 120
    116 0 111 11 124 111 124 86 0 105 9 119 58 13 49 13 555 0 604 -14 49 -33 58
    -119 58 -99 0 -111 13 -111 120 0 108 11 120 118 120 42 0 82 4 89 8 25 16 33
    69 33 232 0 163 -8 216 -33 232 -17 11 -169 9 -187 -2z"/>
    <path d="M3136 2624 c-12 -12 -16 -37 -16 -104 0 -116 4 -120 120 -120 116 0
    120 4 120 120 0 116 -4 120 -120 120 -67 0 -92 -4 -104 -16z"/>
    <path d="M2176 2384 c-12 -12 -16 -37 -16 -104 0 -116 4 -120 120 -120 67 0
    92 4 104 16 12 12 16 37 16 104 0 116 -4 120 -120 120 -67 0 -92 -4 -104 -16z"/>
    <path d="M357 1670 c-190 -23 -278 -81 -317 -206 -36 -116 -42 -236 -38 -696
    4 -407 6 -438 27 -513 38 -140 86 -188 226 -227 78 -22 93 -22 585 -22 492 0
    507 0 585 22 140 39 188 87 227 227 22 78 22 93 22 585 0 492 0 507 -22 585
    -39 140 -87 188 -227 227 -76 20 -102 22 -540 24 -253 1 -491 -2 -528 -6z
    m797 -240 c210 -20 256 -66 276 -276 13 -140 13 -488 0 -628 -15 -156 -46
    -214 -132 -250 -115 -48 -801 -48 -916 0 -86 36 -117 94 -132 250 -13 140 -13
    488 0 628 20 205 68 257 260 275 125 12 519 13 644 1z"/>
    <path d="M575 1182 c-41 -14 -54 -24 -70 -57 -18 -37 -20 -60 -20 -285 0 -225
    2 -248 20 -285 32 -65 75 -74 335 -74 260 0 303 9 335 74 18 37 20 60 20 285
    0 225 -2 248 -20 285 -32 65 -75 74 -335 74 -176 0 -224 -3 -265 -17z"/>
    <path d="M5776 704 c-12 -12 -16 -36 -16 -100 0 -113 -10 -124 -117 -124 -43
    0 -83 -4 -90 -8 -25 -16 -33 -69 -33 -223 0 -185 9 -220 60 -238 42 -14 339
    -15 377 0 39 15 43 24 43 116 0 108 -5 113 -116 113 -114 0 -124 10 -124 120
    0 110 10 120 124 120 112 0 116 5 116 120 0 116 -4 120 -120 120 -67 0 -92 -4
    -104 -16z"/>
    </g>
    </g></svg>
  )
} 

export default QR_Code


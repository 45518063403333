import React from "react";
import AppStore from "./pages/PagesWithoutTransition/AppStore";
import Download from "./pages/DownloadAppLetz/Download";
import DriverFrom from "./pages/Driver/BecomeADriver/DriverFrom";
import DriverDownloadAPK from "./pages/Driver/DownloadDriverApp/DriverDownloadAPK";
import DriverInstruction from "./pages/Driver/Instruction/DriverInstruction";
import DriverNew from "./pages/PagesWithoutTransition/DriverNew";
import GooglePlay from "./pages/PagesWithoutTransition/GooglePlay";
import Join from "./pages/PagesWithoutTransition/Join";
import Main from "./pages/Main/Main";
import PageNotFound from "./pages/Page_404/Page_404";
import Forget from "./pages/PagesWithoutTransition/Forget/Forget";
import PrivacyPolicy from "./pages/PagesWithoutTransition/PrivacyPolicy/PrivacyPolicy";

const AppRoutes = [
  { path: "*", element: <PageNotFound /> },
  { path: "/", element: <Main /> },
  { path: "/become_a_driver", element: <DriverFrom /> },
  { path: "/driver/new", element: <DriverNew /> },
  { path: "/driver/instruction", element: <DriverInstruction /> },
  { path: "/driver", element: <DriverDownloadAPK /> },
  { path: "/download", element: <Download /> },
  { path: "/ios", element: <AppStore /> },
  { path: "/android", element: <GooglePlay /> },
  { path: "/join", element: <Join /> },
  { path: "/forget", element: <Forget /> },
  { path: "privacy-policy", element: <PrivacyPolicy /> },
  { path: "privacy-terms", element: <PrivacyPolicy /> },
];

export default AppRoutes;

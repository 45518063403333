import { useEffect } from 'react'

function AppStore() {

  useEffect(() => {
		window.location.href = 'https://apps.apple.com/ru/app/letz/id1624080667'    
	});

  return (<>
  
  </>)  
}

export default AppStore
const style = {
  container: { mt: "105px", minHeight: "calc(100vh - 205px)", width: "100%" },
  parent: { mb: { xs: "80px", sm: "40px", md: "0" }, textAlign: "center" },
  title: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "42px",
    letterSpacing: "-2.5px",
    fontWeight: 700,
  },
  mainChild: {
    display: "flex",
    justifyContent: "center",
    flexDirection: { xs: "column", md: "row" },
    pt: { xs: "0", sm: "20px", md: "20px", lg: "20px" },
    mt: { xs: "50px", md: "0px" },
  },
  imgBox: {
    display: "flex",
    justifyContent: { xs: "center", md: "start" },
    pt: "0px",
    width: {
      xs: "100%",
      sm: "100%",
      md: (1242 / 100) * 28,
      lg: (1242 / 100) * 30,
    },
  },
  img: {
    borderLeft: "black solid 2px",
    borderBottom: "solid black 2px",
    width: {
      xs: (1242 / 100) * 20,
      sm: (1242 / 100) * 30,
      md: (1242 / 100) * 28,
      lg: (1242 / 100) * 30,
    },
    height: {
      xs: (2190 / 100) * 20,
      sm: (2190 / 100) * 30,
      md: (2190 / 100) * 28,
      lg: (2190 / 100) * 30,
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "start" },
    pt: {
      xs: "15px",
      sm: "15px",
      md: "calc(3vh - 10px)",
      lg: "15px",
    },
    width: { xs: "100%", sm: "100%", md: "61%", lg: "69%" },
  },
  content: {
    marginTop: {
      xs: "20px",
      sm: "20px",
      md: "10px",
      lg: "20px",
    },
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: {
      xs: "15.5px",
      sm: "15.5px",
      md: "14.5px",
      lg: "15.5px",
    },
    lineHeight: {
      xs: "20px",
      sm: "20px",
      md: "17px",
      lg: "20px",
    },
    textAlign: "left",
    color: "#3e4958",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    mt: { xs: "30px", sm: "20px", md: "20px", lg: 0 },
  },
};

export default style;

const style = {
  parent: {
    mt: "100px",
    height: "calc(100vh - 200px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  child: { display: "flex", flexDirection: "column" },
  description: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: { xs: "4.6vw", sm: "28px" },
    fontWeight: 400,
  },
  email: {
    textAlign: "center",
    mt: { xs: "5.5vw", sm: "35px" },
    fontFamily: "Montserrat",
    fontSize: { xs: "4.6vw", sm: "28px" },
    fontWeight: 500,
  },
};

export default style;

import { Box, Container } from "@mui/system";
import LetzLogoNotFound from "../../images/logo_letz_not_found.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Typography } from "@mui/material";
import style from "./Style";

function PageNotFound() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container sx={style.container}>
      <Box sx={style.parent}>
        <Box sx={style.child}>
          <Box sx={style.imgBox}>
            <img
              src={LetzLogoNotFound}
              alt="LetzLogoNotFound"
              style={{
                height: !smUp ? "22vw" : 132,
                width: !smUp ? "90%" : 512,
              }}
            />
          </Box>
          <Typography sx={style.description}>404 Page Not Found</Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default PageNotFound;

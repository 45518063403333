import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Box sx={{minHeight: '100vh', display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
      <CircularProgress size={100} sx={{color: '#797af0'}}/>
    </Box>
  );
};

export default Loading;
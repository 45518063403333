const LocationPin = ({ width, height }) => {
  // width="722"
  // height="442"
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 722 442"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15038_35280)">
        <rect width="722" height="441.864" fill="white" />
        <path
          opacity="0.18"
          d="M-21.1621 18.4809C-21.5513 9.07973 -21.0868 -0.909185 -20.7412 -11.416C-19.5201 -51.5774 -1.09078 -87.6171 16.6334 -121.433L101.466 -283.397C115.767 -310.672 130.818 -338.829 155.91 -356.378C170.56 -366.384 187.631 -372.641 205.785 -374.659C234.391 -378.171 265.567 -372.805 293.844 -362.137C340.101 -344.677 381.3 -314.783 423.909 -288.666C448.724 -273.45 474.427 -259.272 496.355 -239.89C518.283 -220.508 845.452 -241.424 849.076 -213.356C853.935 -175.625 956.593 -162.113 943.578 -129.858C929.851 -95.8407 813.562 169.422 812 206.499C882 190.499 642 107 597.999 114C549 103.5 409.393 195.845 369.709 195.918C342.328 195.962 314.772 189.073 289.586 175.886C259.982 160.364 240.5 155.499 203 155.499C138 155.499 -8.37878 258.809 -66.5 228.5C-96.8516 212.687 -20.156 43.485 -21.1621 18.4809Z"
          fill="#B8B8B8"
        />
        <path
          d="M658.714 78.6894C654.108 76.1768 652.014 78.2706 650.325 76.1768C648.635 74.083 654.946 68.6247 642.368 63.1808C642.368 63.1808 641.531 51.4411 624.333 51.0223C607.135 50.6036 611.813 62.7043 607.149 65.6934C602.485 68.6825 601.272 64.0183 596.666 66.9496C592.059 69.881 594.139 75.7581 590.37 76.5956C586.601 77.4331 581.562 72.8267 576.955 75.7581C572.349 78.6894 572.334 81.2019 570.241 81.2019C568.147 81.2019 563.959 79.5269 561.432 81.2019C558.905 82.877 555.988 84.9852 555.988 84.9852H662.065C662.065 84.9852 663.335 81.1442 658.714 78.6894Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M351.128 47.2768C348.24 45.7172 346.94 47.0168 345.886 45.7172C344.832 44.4176 348.774 40.9954 340.919 37.602C340.919 37.602 340.399 30.2664 329.67 30.0065C318.941 29.7466 321.815 37.342 318.927 39.1615C316.039 40.9809 315.274 38.1218 312.386 39.9557C309.498 41.7895 310.826 45.4429 308.458 45.9772C306.09 46.5114 302.971 43.6234 300.097 45.4429C297.224 47.2623 297.209 48.8507 295.909 48.8507C294.61 48.8507 291.982 47.7966 290.408 48.8507C288.834 49.9048 287 51.2044 287 51.2044H353.222C353.405 50.4073 353.294 49.571 352.909 48.8494C352.524 48.1277 351.892 47.5692 351.128 47.2768Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M130.656 110.567C128.562 109.427 127.609 110.38 126.858 109.427C126.107 108.474 128.952 106.004 123.233 103.535C123.233 103.535 122.858 98.1924 115.046 98.0047C107.234 97.817 109.342 103.333 107.248 104.676C105.155 106.019 104.591 103.911 102.498 105.239C100.404 106.568 101.342 109.239 99.6097 109.571C97.8769 109.903 95.6098 107.867 93.516 109.196C91.4222 110.524 91.4222 111.665 90.4691 111.665C89.5161 111.665 87.5811 110.914 86.4692 111.665C85.3574 112.416 84 113.383 84 113.383H132.157C132.281 112.812 132.198 112.216 131.923 111.7C131.648 111.185 131.199 110.783 130.656 110.567Z"
          fill="white"
          stroke="black"
          strokeWidth="1.444"
          strokeMiterlimit="10"
        />
        <path
          d="M46 426L681 426"
          stroke="black"
          strokeWidth="1.444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M630.508 341.32C630.508 369.475 614.718 371.603 595.245 371.603C575.772 371.603 559.973 369.475 559.973 341.32C559.973 313.164 575.762 254.303 595.245 254.303C614.728 254.303 630.508 313.164 630.508 341.32Z"
          fill="#B7B7B7"
        />
        <path
          opacity="0.58"
          d="M630.508 341.32C630.508 369.475 614.718 371.603 595.245 371.603C575.772 371.603 559.973 369.475 559.973 341.32C559.973 313.164 575.762 254.303 595.245 254.303C614.728 254.303 630.508 313.164 630.508 341.32Z"
          fill="white"
        />
        <path
          d="M607.159 354.095L601.328 354.497L616.525 344.258L605.674 349.739L607.159 345.453L602.201 351.495L596.379 354.437L595.927 334.211L598.999 326.732L595.847 330.938L595.576 319.214L605.242 306.326L595.516 316.293L594.923 290.486L594.402 306.998L588.198 300.082L594.301 310.18L593.478 336.339L588.078 332.805L583.159 324.093L585.137 330.878L575.48 324.574L593.358 339.992L590.657 425.865H598.015L596.459 357.779L598.166 356.635L607.159 354.095Z"
          fill="black"
        />
        <path
          d="M717.383 289.936C717.383 335.216 691.988 338.628 660.67 338.628C629.352 338.628 603.957 335.216 603.957 289.936C603.957 244.656 629.352 150 660.67 150C691.988 150 717.383 244.635 717.383 289.936Z"
          fill="#B7B7B7"
        />
        <path
          opacity="0.31"
          d="M717.383 289.936C717.383 335.216 691.988 338.628 660.67 338.628C629.352 338.628 603.957 335.216 603.957 289.936C603.957 244.655 629.352 150 660.67 150C691.988 150 717.383 244.635 717.383 289.936Z"
          fill="white"
        />
        <path
          d="M679.84 310.466L670.465 311.108L694.897 294.636L677.441 303.459L679.84 296.564L671.87 306.28L662.505 311.008L661.762 278.496L666.721 266.46L661.642 273.226L661.21 254.375L676.758 233.657L661.11 249.677L660.156 208.182L659.323 234.741L649.346 223.62L659.162 239.841L657.837 281.909L649.155 276.237L641.245 262.215L644.417 273.136L628.889 262.987L657.647 287.791L653.29 425.869H665.135L662.625 316.388L665.366 314.541L679.84 310.466Z"
          fill="black"
        />
        <path
          d="M93.4707 341.32C93.4707 369.475 109.26 371.603 128.743 371.603C148.226 371.603 164.006 369.475 164.006 341.32C164.006 313.164 148.216 254.303 128.743 254.303C109.27 254.303 93.4707 313.164 93.4707 341.32Z"
          fill="#B7B7B7"
        />
        <path
          opacity="0.58"
          d="M93.4707 341.32C93.4707 369.475 109.26 371.603 128.743 371.603C148.226 371.603 164.006 369.475 164.006 341.32C164.006 313.164 148.216 254.303 128.743 254.303C109.27 254.303 93.4707 313.164 93.4707 341.32Z"
          fill="white"
        />
        <path
          d="M116.816 354.095L122.648 354.497L107.451 344.258L118.302 349.739L116.816 345.453L121.775 351.495L127.597 354.437L128.059 334.211L124.977 326.732L128.129 330.938L128.4 319.214L118.734 306.326L128.47 316.293L129.052 290.486L129.574 306.998L135.778 300.082L129.675 310.18L130.508 336.339L135.898 332.805L140.816 324.093L138.849 330.878L148.495 324.574L130.618 339.992L133.328 425.865H125.961L127.517 357.779L125.82 356.635L116.816 354.095Z"
          fill="black"
        />
        <path
          d="M6.97461 290.37C6.97461 335.54 32.2796 338.902 63.4969 338.902C94.7141 338.902 120.019 335.499 120.019 290.37C120.019 245.241 94.7141 150.906 63.4969 150.906C32.2796 150.906 6.97461 245.241 6.97461 290.37Z"
          fill="#B7B7B7"
        />
        <path
          opacity="0.5"
          d="M6.97461 290.37C6.97461 335.54 32.2796 338.902 63.4969 338.902C94.7141 338.902 120.019 335.5 120.019 290.37C120.019 245.241 94.7141 150.906 63.4969 150.906C32.2796 150.906 6.97461 245.241 6.97461 290.37Z"
          fill="white"
        />
        <path
          d="M44.385 310.849L53.7401 311.492L29.3887 295.08L46.774 303.873L44.385 296.997L52.3449 306.683L61.6699 311.391L62.4027 278.989L57.4641 266.994L62.5231 273.74L62.9547 254.949L47.4666 234.292L63.0651 250.262L64.0087 208.906L64.8418 235.376L74.7791 224.294L65.0024 240.465L66.3274 282.392L74.9799 276.731L82.8595 262.769L79.6976 273.649L95.1657 263.531L66.5081 288.254L70.8544 425.871H59.0501L61.5394 316.751L58.8192 314.914L44.385 310.849Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip1_15038_35280)">
        <path
          d="M520.94 199.15C521.151 197.629 520.804 196.082 519.966 194.803C519.128 193.523 517.856 192.6 516.391 192.208C514.927 191.816 513.371 191.982 512.019 192.676C510.667 193.369 509.613 194.541 509.057 195.97L496 211.149L499.831 222L512.962 204.263C513.819 204.542 514.726 204.626 515.618 204.511C516.511 204.395 517.368 204.083 518.128 203.595C518.889 203.108 519.535 202.457 520.02 201.689C520.506 200.921 520.82 200.054 520.94 199.15Z"
          fill="#FFB8B8"
        />
        <path
          d="M465.368 259L510 211.537L503.98 202L448 249.175L465.368 259Z"
          fill="#3F3D56"
        />
        <path
          d="M455.5 230C464.06 230 471 223.06 471 214.5C471 205.94 464.06 199 455.5 199C446.94 199 440 205.94 440 214.5C440 223.06 446.94 230 455.5 230Z"
          fill="#2F2E41"
        />
        <path
          d="M457 205.951C460.388 202.583 464.489 199.983 469.012 198.336C471.916 197.281 475.076 196.63 478.112 197.228C481.148 197.827 484.019 199.886 484.866 202.806C485.558 205.194 484.843 207.765 483.768 210.013C482.694 212.261 481.259 214.345 480.39 216.677C479.503 219.056 479.236 221.615 479.614 224.12C479.991 226.626 481.001 229 482.552 231.026C484.103 233.052 486.145 234.666 488.493 235.722C490.842 236.778 493.422 237.242 496 237.073C493.017 237.465 490.268 238.839 487.352 239.57C484.437 240.302 480.996 240.252 478.829 238.205C476.537 236.038 476.491 232.515 476.602 229.395C476.766 224.758 476.931 220.12 477.096 215.482C477.18 213.117 477.252 210.676 476.382 208.469C475.512 206.261 473.444 204.328 471.03 204.348C469.201 204.364 467.582 205.45 466.115 206.522C464.647 207.593 463.095 208.736 461.272 208.896C459.45 209.055 457.37 207.733 457.506 205.944L457 205.951Z"
          fill="#2F2E41"
        />
        <path
          d="M397.008 318.77C395.518 319.183 393.928 319.036 392.539 318.357C391.15 317.679 390.059 316.515 389.472 315.087C388.886 313.658 388.844 312.065 389.356 310.609C389.868 309.152 390.897 307.933 392.249 307.183L405.658 292L417 294.446L401.048 310.04C401.44 310.869 401.645 311.774 401.649 312.69C401.653 313.607 401.456 314.514 401.07 315.346C400.685 316.178 400.122 316.916 399.42 317.508C398.718 318.099 397.895 318.53 397.008 318.77Z"
          fill="#FFB8B8"
        />
        <path
          d="M441 264.306L407.279 307L397 302.24L436.635 240L441 264.306Z"
          fill="#3F3D56"
        />
        <path
          d="M414 391.129L406 391L406.962 419H413.999L414 391.129Z"
          fill="#FFB8B8"
        />
        <path
          d="M416 416.001L401.953 416H401.952C399.578 416 397.301 416.918 395.622 418.553C393.943 420.187 393 422.404 393 424.716V424.999L416 425L416 416.001Z"
          fill="#2F2E41"
        />
        <path
          d="M466.962 384L458 389.686L475.927 411L482 407.147L466.962 384Z"
          fill="#FFB8B8"
        />
        <path
          d="M481.99 404L470.129 411.513L470.129 411.513C468.125 412.783 466.713 414.786 466.206 417.082C465.699 419.379 466.137 421.78 467.424 423.758L467.581 424L487 411.7L481.99 404Z"
          fill="#2F2E41"
        />
        <path
          d="M469.97 301.416C469.97 301.416 450.683 236.903 444.485 236.032C438.287 235.16 428.3 252.585 428.3 252.585C415.352 269.223 402 323.116 402 323.116C402 323.116 424.258 324.519 440.528 333.237C456.799 341.954 476 332.795 476 332.795L469.97 301.416Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M469.97 301.416C469.97 301.416 450.683 236.903 444.485 236.031C438.287 235.16 430.836 252.761 430.836 252.761C424.384 279.619 402 323.116 402 323.116C402 323.116 424.258 324.519 440.528 333.237C456.799 341.955 476 332.795 476 332.795L469.97 301.416Z"
          fill="black"
        />
        <path
          d="M454.324 281.503V313.964L458.106 360.985L477 396.553L467.982 403.06L442.218 369.227L432.027 335.632L416.024 410L404 409.132L412.35 306.023L428.196 277L454.324 281.503Z"
          fill="#2F2E41"
        />
        <path
          d="M434.399 250.655C428.306 256.69 433.963 263.155 433.963 263.155L427 278.672L454.418 286L464 243.596L457.472 236.699C444.416 236.268 444.408 236 444.408 236C442.121 238.217 434.399 250.655 434.399 250.655Z"
          fill="#7879F1"
        />
        <path
          d="M465.364 239.258C464.11 238.35 462.686 237.696 461.173 237.332C459.661 236.968 458.089 236.903 456.55 237.139C455.012 237.375 453.536 237.909 452.209 238.709C450.882 239.509 449.729 240.559 448.819 241.799C441.882 251.277 435.469 266.58 444.306 285.667C458.495 316.313 470.843 332.002 475.863 332C476.082 332.002 476.3 331.968 476.508 331.9C479.598 330.872 484.065 325.741 484.874 321.22C485.164 319.601 485.095 317.347 483.122 315.799C477.457 311.353 467.287 297.508 464.114 279.285C462.361 269.215 465.761 260.043 468.922 254.122C470.257 251.672 470.648 248.832 470.022 246.122C469.397 243.412 467.797 241.014 465.516 239.368L465.364 239.258Z"
          fill="#3F3D56"
        />
        <path
          d="M456.608 228.555C462.165 225.519 464.208 218.555 461.17 213.001C458.132 207.447 451.164 205.406 445.606 208.442C440.048 211.478 438.006 218.442 441.044 223.996C444.082 229.55 451.05 231.591 456.608 228.555Z"
          fill="#FFB8B8"
        />
        <path
          d="M435 218H451.51L451.679 215.548L452.526 218H455.068L455.403 213.141L457.081 218H462V217.759C461.996 214.376 460.695 211.133 458.383 208.741C456.07 206.349 452.935 205.004 449.664 205H447.336C444.066 205.004 440.93 206.349 438.617 208.741C436.305 211.133 435.004 214.376 435 217.759V218Z"
          fill="#2F2E41"
        />
        <path
          d="M459.588 225.872L467 223.647V207H453L453.347 207.32C458.169 211.775 463.532 227.578 459.588 225.872Z"
          fill="#2F2E41"
        />
        <path
          d="M370.476 193.612H368.796V147.619C368.796 140.559 365.989 133.789 360.993 128.797C355.997 123.805 349.221 121 342.156 121H244.64C237.574 121 230.799 123.804 225.803 128.797C220.807 133.789 218 140.559 218 147.619V399.937C218 406.997 220.807 413.767 225.803 418.759C230.798 423.751 237.574 426.556 244.64 426.556H342.156C345.654 426.556 349.119 425.867 352.351 424.53C355.583 423.192 358.519 421.231 360.993 418.759C363.467 416.288 365.429 413.353 366.768 410.124C368.107 406.894 368.796 403.433 368.796 399.937V226.35H370.476L370.476 193.612Z"
          fill="black"
        />
        <path
          d="M363.126 147.807V399.754C363.128 402.362 362.617 404.945 361.62 407.356C360.623 409.767 359.161 411.958 357.317 413.804C355.473 415.65 353.283 417.115 350.872 418.115C348.461 419.115 345.877 419.631 343.267 419.634H245.247C239.974 419.637 234.915 417.548 231.184 413.825C227.453 410.102 225.355 405.051 225.352 399.782V147.807C225.35 145.199 225.862 142.615 226.86 140.204C227.857 137.793 229.32 135.602 231.165 133.756C233.009 131.91 235.2 130.445 237.611 129.445C240.023 128.445 242.608 127.93 245.218 127.928H257.133C256.547 129.362 256.324 130.918 256.482 132.458C256.64 133.999 257.175 135.477 258.04 136.762C258.905 138.048 260.072 139.101 261.44 139.829C262.808 140.557 264.334 140.938 265.884 140.938H321.752C323.302 140.938 324.828 140.557 326.196 139.829C327.564 139.101 328.731 138.048 329.596 136.762C330.461 135.477 330.996 133.999 331.154 132.458C331.312 130.918 331.089 129.362 330.503 127.928H343.231C348.503 127.923 353.561 130.011 357.292 133.733C361.023 137.454 363.121 142.504 363.126 147.772L363.126 147.807Z"
          fill="white"
        />
        <path
          d="M363 185.016V176.455H349.98V129.224C347.769 128.412 345.43 127.997 343.072 128H341.378V176.455H296.215V140.978H287.613V176.455H249.762V128H244.928C243.663 127.998 242.401 128.117 241.16 128.355V176.455H225V185.016H241.16V204.818L225 214.102V223.99L241.16 214.706V273.622H225V282.183H241.16V367.365H225V375.926H241.16V418.645C242.401 418.883 243.663 419.002 244.928 419H249.762V375.925H287.613V419H296.215V375.925H341.378V419H343.072C345.43 419.003 347.769 418.588 349.98 417.776V375.926H363V367.365H349.98V330.124H362.884V321.563H349.98V282.183H363V273.622H349.98V221.4H363V212.839H349.98V185.016L363 185.016ZM275.626 185.016L249.762 199.878L249.762 185.016H275.626ZM249.762 209.762L287.613 188.013V273.622H249.762V209.762ZM249.762 367.365V282.183H287.613V367.365H249.762ZM341.378 367.365H296.215V330.124H341.378V367.365ZM341.378 321.563H296.215V282.183H341.378V321.563ZM341.378 273.622H296.215V221.4H341.378V273.622ZM341.378 212.839H296.215V185.016H341.378V212.839Z"
          fill="#E5E5E5"
        />
        <path
          d="M293.966 322.06C300.034 322.06 304.952 317.141 304.952 311.074C304.952 305.006 300.034 300.088 293.966 300.088C287.899 300.088 282.98 305.006 282.98 311.074C282.98 317.141 287.899 322.06 293.966 322.06Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M293.965 302.79C289.39 302.79 285.681 306.499 285.681 311.074C285.681 315.649 289.39 319.358 293.965 319.358C298.54 319.358 302.249 315.649 302.249 311.074C302.249 306.499 298.54 302.79 293.965 302.79ZM280.277 311.075C280.277 303.515 286.406 297.387 293.965 297.387C301.525 297.387 307.653 303.515 307.653 311.075C307.653 318.634 301.525 324.762 293.965 324.762C286.406 324.762 280.277 318.634 280.277 311.075Z"
          fill="#FDFDFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M291.303 277.865H296.203V309.136H291.303V277.865Z"
          fill="black"
        />
        <path
          d="M293.967 279.216C309.742 279.216 322.531 266.428 322.531 250.653C322.531 234.878 309.742 222.09 293.967 222.09C278.192 222.09 265.404 234.878 265.404 250.653C265.404 266.428 278.192 279.216 293.967 279.216Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M293.967 223.44C278.938 223.44 266.754 235.623 266.754 250.652C266.754 265.681 278.938 277.864 293.967 277.864C308.996 277.864 321.179 265.681 321.179 250.652C321.179 235.623 308.996 223.44 293.967 223.44ZM264.053 250.652C264.053 234.131 277.446 220.738 293.967 220.738C310.488 220.738 323.881 234.131 323.881 250.652C323.881 267.174 310.488 280.566 293.967 280.566C277.446 280.566 264.053 267.174 264.053 250.652Z"
          fill="black"
        />
        <path
          d="M293.968 259.443C298.822 259.443 302.757 255.508 302.757 250.654C302.757 245.8 298.822 241.865 293.968 241.865C289.114 241.865 285.18 245.8 285.18 250.654C285.18 255.508 289.114 259.443 293.968 259.443Z"
          fill="#F9FFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_15038_35280">
          <rect width="722" height="441.864" fill="white" />
        </clipPath>
        <clipPath id="clip1_15038_35280">
          <rect
            width="435.337"
            height="331"
            fill="white"
            transform="translate(143 94)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationPin;

const boldRows = [0, 5, 10, 11, 17, 40, 45, 48, 51, 54, 63, 66, 68, 70, 74, 76];

export function Rows() {
  let rows = [];
  for (let i = 0; i < 86; i++) {
    if (boldRows.find((r) => r === i) || i === 0) {
      rows.push(i + "_bold");
    } else {
      rows.push(i + "");
    }
  }
  return rows;
}

export const style = {
  parent: {
    mt: "120px",
    mb: "50px",
    mx: "5vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
};

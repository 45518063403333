import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import Backend  from 'i18next-http-backend';
import { getLanguage } from './language';

const browserlang = window.navigator.language 

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../languages/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .init({
    fallbackLng: 'en',
    lng: getLanguage() ?? browserlang,
    ns: ['common', 'sign_in'],
    interpolation: {
      escapeValue: false,
    },
  });
 
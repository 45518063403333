import React, { useEffect } from 'react';

const Join = () => {

	useEffect(() => {

			if (/Android/i.test(navigator.userAgent)) {
				console.log(navigator.userAgent);
				window.location.href = 'https://play.google.com/store/apps/details?id=md.letz.client'
			} else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				console.log(navigator.userAgent);
				window.location.href = 'https://apps.apple.com/ru/app/letz/id1624080667'
			} else {
				console.log(navigator.userAgent);
				window.location.href = 'https://letz.md/download'
			}

	});

	return (<>

	</>)
}

export default Join
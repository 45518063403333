import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Rows, style } from "./utils";

function Privacy() {
  const { t } = useTranslation("privacy");

  return (
    <>
      <Box sx={style.parent}>
        {Rows().map((row, i) => (
          <Typography
            key={i}
            sx={{ margin: "10px 0", fontWeight: row.length < 4 ? 500 : 700 }}
          >
            {t(row)}
          </Typography>
        ))}
      </Box>
    </>
  );
}

export default Privacy;

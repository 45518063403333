import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import style from "./style";

const Forget = () => {
  const { t } = useTranslation("PagesWithoutTransition");
  return (
    <Container>
      <Box sx={style.parent}>
        <Box sx={style.child}>
          <Typography sx={style.description}>
            {t("to_delete_your_data")}
          </Typography>
          <Typography sx={style.email}>Email: support@letz.md</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Forget;

import "./style.css";
import {ScreenResolutions} from "./utils"

const SnowAnimation = () => {
  const snowflakesOnResolution = 30;

  const countSnowFlakes =
    ScreenResolutions().reduce(
      (count, breakpoints) => (breakpoints ? count + 1 : count),
      0
    ) * snowflakesOnResolution;

  const snowflakes = [];
  for (let i = 0; i < countSnowFlakes; i++) {
    if (i < countSnowFlakes / 3) {
      snowflakes.push(1);
    } else if (i < (countSnowFlakes / 3) * 2) {
      snowflakes.push(2);
    } else {
      snowflakes.push(3);
    }
  }

  const snow = snowflakes.map((item, i) => {
    return (
      <div
        className="snow"
        key={i}
        style={{
          top: `-${(Math.random() * 100 + 5).toFixed(0)}px`,
          left: `${(Math.random() * 100).toFixed(0)}%`,
          fontSize: `${(Math.random() * 15 + 5).toFixed(0)}px`,
          animationDuration: `${(Math.random() * 10 + 3).toFixed(1)}s`,
          userSelect: "none",
        }}
      >
        {item === 1 && <>&#10052;</>}
        {item === 2 && <>&#10053;</>}
        {item === 3 && <>&#10054;</>}
      </div>
    );
  });

  return snow;
};

export default SnowAnimation;

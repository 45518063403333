import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import App from './App';
import '../src/lib/i18n';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-55BJ4TM'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // React.StrictMode making double rendering
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>         
  </React.StrictMode>
);

reportWebVitals();

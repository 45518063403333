import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import GooglePlayButtonAPK from "../../../components/buttons/GooglePlayButtonAPK";
import "../../../styles/App.css";
import AppDriver from "../../../images/AppDriver.png";
import style from "./style";
import { data, getImg } from "./utils";

function Download() {
  const { t } = useTranslation(["download", "common"]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Container sx={style.container}>
        <Box sx={style.parent}>
          <Typography sx={style.title}>{t("title")}</Typography>
          <Box sx={style.mainChild}>
            <Box sx={style.imgBox}>
              <Box
                component="img"
                sx={style.img}
                src={AppDriver}
                alt="mobile app"
              ></Box>
            </Box>
            <Box sx={style.contentBox}>
              {data.map((text, i) => (
                <Typography key={i} sx={style.content}>
                  {getImg(i)}
                  {t(text)}
                </Typography>
              ))}
              <Box sx={style.buttonBox}>
                <GooglePlayButtonAPK />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Download;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Loading from "./components/Loading";
import { Suspense } from "react";
import Footer from "./components/Footer";
import AppRoutes from "./Routes";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <div className="App">
      <Header />
      <Suspense fallback={<Loading />}>
        <SnackbarProvider maxSnack={2} autoHideDuration={1500}>
          <Routes>
            {AppRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </SnackbarProvider>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;

import { useEffect } from 'react'

function GooglePlay() {

  useEffect(() => {
		window.location.href = 'https://play.google.com/store/apps/details?id=md.letz.client'    
	});

  return (<>
  
  </>)  
}

export default GooglePlay
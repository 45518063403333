import React from "react";
import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

function Footer() {
  const { t } = useTranslation("footer");

  return (
    <>
      <Box
        sx={{
          height: "80px",
          width: "100%",
          mt: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#000",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: "#fff",
          userSelect: "none",
        }}
      >
        <Box sx={{ mt: "12px", mb: "4px" }}>
          <Link
            href="https://www.facebook.com/people/Letz/100083091554230/"
            target="black"
            sx={{
              color: "#fff",
              mr: 2,
              pt: 1,
              "&:hover": {
                cursor: "pointer",
                color: "#0866ff",
                boxShadow: "inset 0px -3px 0px 4px #000",
                backgroundColor: "#fff",
              },
            }}
          >
            <FacebookIcon />
          </Link>
          <Link
            href="https://www.instagram.com/letz.moldova/?igshid=YWVjMmZiZjg%3D"
            target="black"
            sx={{
              color: "#fff",
              mr: 2.5,
              pt: 1,
              "&:hover": {
                cursor: "pointer",
                boxShadow: "inset 0px -3px 0px 4px #000",
                background:
                  "linear-gradient(160deg, #950dfa 0%, #fc1375 35%, #fdbc00 70%)",
              },
            }}
          >
            <InstagramIcon />
          </Link>
          <Link
            href="https://www.youtube.com/@LetzMoldova/featured"
            target="black"
            sx={{
              color: "#fff",
              pt: 1,
              "&:hover": {
                color: "#ff0000",
                cursor: "pointer",
                boxShadow: "inset 0px -5px 0px 7px #000",
                backgroundColor: "#fff",
              },
            }}
          >
            <YouTubeIcon
              fontSize="large"
              sx={{ m: -0.5, width: "32px", height: "32px" }}
            />
          </Link>
        </Box>
        © Letz 2024, {t("all_rights_reserved")}
      </Box>
    </>
  );
}

export default Footer;
